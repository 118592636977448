import { Link } from 'react-router-dom';
import { defined } from '../../utils/variableEvaluation';
import { renderSwitch } from '../../utils/react';
import * as React from 'react';
import classNames from 'classnames';
// import './Button.scss';
import styles from './Button.module.scss'; // Import css modules stylesheet as styles

export enum EButtonType {
  PillMedium = 'Pill Medium',
  InvertedPillMedium = 'Inverted Pill Medium',
  PillSmall = 'Pill Small',
  FullWidth = 'Full Width',
  FullWidthInverted = 'Full Width Inverted',
  BasicLink = 'Basic Link'
}

enum EButtonActionType {
  InternalLink = 'Internal Link',
  ExternalLink = 'External Link',
  NormalButton = 'Normal Button'
}


interface IProps {
  buttonType?: EButtonType;
  to?: string;
  href?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  submit?: boolean;
  className?: string;
  title?: string;
  onClick?: any;
}

export class Button extends React.Component<IProps> {
  private action(): string {
    if (defined(this.props.to)) {
      return EButtonActionType.InternalLink;
    } else if (defined(this.props.href)) {
      return EButtonActionType.ExternalLink;
    } else {
      return EButtonActionType.NormalButton;
    }
  }

  private renderButton() {
    const {
      isDisabled,
      isLoading,
      title,
      buttonType,
      to,
      href,
      children,
      className,
      onClick,
      submit,
      ...remainingProps
    } = this.props;

    const buttonClass = classNames(styles.button, className, {
      [`${styles.pill} ${styles.medium}`]: buttonType === EButtonType.PillMedium,
      [`${styles.pill} ${styles.small}`]: buttonType === EButtonType.PillSmall,
      [`${styles.pill} ${styles.fullWidth}`]: buttonType === EButtonType.FullWidth,
      [`${styles.pill} ${styles.fullWidthInverted}`]: buttonType === EButtonType.FullWidthInverted,
      [`${styles.invertedPill} ${styles.medium}`]:
        buttonType === EButtonType.InvertedPillMedium,
      [styles.basicLink]: buttonType === EButtonType.BasicLink
    });

    return renderSwitch(this.action(), {
      [EButtonActionType.InternalLink]: () => (
        <Link
          key={`Link-${to}`}
          to={(to as string)}
          className={buttonClass}
          role="button"
          {...remainingProps}
        >
            <span className={styles.buttonChild + ' buttonChild'}>
                {children}
            </span>
          <span className={styles.mask} />
        </Link>
      ),
      [EButtonActionType.ExternalLink]: () => (
        <a
          key={`a-${href}`}
          href={href}
          className={buttonClass}
          role="button"
          target="_blank"
          rel="noopener noreferrer"
          {...remainingProps}
        >
        <span className={styles.buttonChild + ' buttonChild'}>
            {children}
        </span>
          <span className={styles.mask} />
        </a>
      ),
      [EButtonActionType.NormalButton]: () => (
        <button key="button" className={buttonClass} disabled={isDisabled} onClick={onClick} type={submit ? "submit" : "button"} title={title}>
        <span className={styles.buttonChild + ' buttonChild'}>
            {children}
        </span>
          <span className={styles.mask} />
        </button>
      )
    });
  }

  public render() {
    return this.renderButton();
  }
}
