import * as React from 'react';
import classNames from 'classnames';

import styles from './InputCheckbox.module.scss'; // Import css modules stylesheet as styles
// const Fragment = (React as any).Fragment;

interface IProps {
  isFocused: boolean;
  isLoading: boolean;
  name?: string;
  value?: string;
  fullWidth?: boolean;
  checked?: boolean;
  defaultValue?: boolean;
  label: string;
  type: string;
  onChange: (name: string, value: boolean) => void;
  additionalClass?: string;
  isDisabled?: boolean;
}

export function InputCheckbox(props: IProps) {
  return (
    <label
      htmlFor={props.name}
      className={classNames(styles.checkboxLabel, {
        fullWidth: props.fullWidth
      })}
      style={
        props.name === 'agb-read-and-accepted'
          ? { paddingLeft: '80px', zIndex: 1 }
          : {}
      }
    >
      {props.label}
      <input
        id={props.name}
        className={styles.checkboxInput}
        type="checkbox"
        name={props.name}
        checked={props.checked}
        defaultChecked={props.defaultValue}
        value={props.value}
        disabled={props.isDisabled}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          props.onChange(e.currentTarget.name, e.currentTarget.checked);
        }}
      />
      <i className={styles.checkboxMark} />
    </label>
  );
}
