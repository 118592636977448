import { combineReducers } from 'redux';
import app, { AppState } from './app';
import localesReducer from './i18n';
import { connectRouter } from 'connected-react-router'
import { intlReducer } from 'react-intl-redux'


export interface RootState {
  app: AppState;
  intl: any;
  locales: any;
}


export default (history) => combineReducers({
  router: connectRouter(history),
  app,
  intl: intlReducer,
  locales: localesReducer
})
