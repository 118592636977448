export const SIDEBAR_SET = 'SIDEBAR_SET';
export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const SPINNER_SET = 'SPINNER_SET';
export const USERLIST_SET = 'USERLIST_SET';
export const REMOVE_FROM_USERLIST = 'REMOVE_FROM_USERLIST';
export const SET_FILTERING_CRITERIA = 'SET_FILTERING_CRITERIA';

export const FETCH_DONE = 'FETCH_DONE';
export const FETCH_INIT = 'FETCH_INIT';
export const FETCH_FAILED = 'FETCH_FAILED';

export const FETCHED_PRODUCTS = 'FETCHED_PRODUCTS';

export const UPDATE_LOCALES = 'UPDATE_LOCALES'
