import * as React from 'react';
import { InputText } from '../Text/index';
import { DropdownInput } from '../Dropdown/index';
import { EInputTextType, IInputConfig } from '../index';

interface IProps {
  isFocused: boolean;
  isLoading: boolean;
  name: string;
  value?: string[] | string | number;
  placeholder: string;
  type: string;
  onChange?: (name: string, value: string) => void;
  onDropdownOpen: (isOpen: boolean) => void;
  additionalClass?: string;
  isDisabled?: boolean;
  clearable?: boolean;
  selectOptions?: IInputConfig[];
  inputTextType?: EInputTextType;
  relativeDropdown?: boolean;
}

export function InputSelect(props: IProps) {
  const {
    name,
    value,
    placeholder,
    type,
    isFocused,
    isLoading,
    isDisabled,
    onChange,
    onDropdownOpen,
    selectOptions,
    inputTextType,
    clearable,
    relativeDropdown
  } = props;
  return (
    <DropdownInput
      dropdownOptions={selectOptions}
      name={name}
      relative={relativeDropdown}
      value={value}
      onOpen={onDropdownOpen}
      onChange={onChange}
      isDisabled={isDisabled}
      clearable={clearable}
    >
      <InputText
        isFocused={isFocused}
        isLoading={isLoading}
        isDisabled={false}
        name={name}
        placeholder={placeholder}
        type={type}
        value={value}
        inputTextType={inputTextType}
      />
    </DropdownInput>
  );
}
