import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../reducers/index';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';
import { updateIntl } from 'react-intl-redux'
import { store } from '../../index';
import Modal from 'react-modal';
import * as actions from '../../actions/';
import Header from '../../components/Header';
import ProductList from '../../components/ProductList';
import ProductRowLarge from '../../components/ProductRowLarge';
import Spinner from '../../components/Spinner';
import { GoogleTagManager } from '../../components/GoogleTagManager';
import { Button, EButtonType } from '../../components/Button/index';
import { Input } from '../../components/Input/index';
import InputSlider from '../../components/Input/InputSlider/index';
import { EValidationTestType } from '../../utils/validationConfigs';
import { defined } from '../../utils/variableEvaluation';
import { isXInArray } from '../../utils/array';
import { getParameterByName } from '../../utils/toSearchParams';
import { ReactComponent as IconArrow } from '../../assets/icons/arrow-down.svg';
import { ReactComponent as IconPlus } from '../../assets/icons/plus.svg';
import { ReactComponent as IconMinus } from '../../assets/icons/minus.svg';
import config from '../../constants/config';
import './SearchPage.scss';
import './print.scss';

const gtmId = config.gtmId;

interface State {
    modalIsOpen: boolean;
    // modalContentId: string;
    productId: string;
    available: boolean;
    availableSoon: boolean;
    filterByZonesNo: number;
    filterByFormatsNo: number;
    filterByAudiencesNo: number;
    filterFormats: string;
    filterZones: string;
    filterAudiences: string;
    zones: any;
    formats: any;
    audiences: any;
    locationAirside: boolean;
    locationLandside: boolean;
    filtersExpanded: boolean;
    // readyToDisplay: boolean;
    price: Array<any>;
    priceMin: number;
    priceMax: number;
    showingNowNo: number;
}

interface Props {
    location: any;
    history: any;
    match: any;
    products: any;
    setSidebar: any;
    setSpinner: any;
    fetchProducts: any;
    closeModal: any;
    setUserList: any;
    setFilters: any;
    sidebarOpened: boolean;
    loading: boolean;
    userList: Array<any>;
    productsObj: any;
    zonesObj: any;
    formatsObj: any;
    filtersNumber: number;
    modalId: number;
    locales: any;
    openModal: any;
    removeFromList: any;
    intl: any;
    addToList: any;
    // updateLocales: any;
}

(window as any).urlObj = {};
// let lastScrollY = 0;
// let ticking = false;
const rowsNumber = 10;
const maxPrice = 1000000;

Modal.setAppElement('#root');

// addLocaleData([...itLocaleData, ...zhLocaleData])


class SearchPage extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        document.body.classList.add("searchPage");
        // this.scrollPosBeforeModal = 0;
        console.log("constructor", this.props.location.search, this.props.match.params.languageCode);
        let modalContentId = '';
        // let languageCode = getParameterByName('lang', this.props.location.search);
        let userlist = getParameterByName('userlist', this.props.location.search);
        let product = getParameterByName('product', this.props.location.search);

        // this.userLang = navigator.language || (navigator as any).userLanguage;

        // if (languageCode) {
        //     (window as any).urlObj['lang'] = languageCode;
        // } else if (this.userLang) {
        //     (window as any).urlObj['lang'] = this.userLang.substr(0,2);
        // } else {
        //     (window as any).urlObj['lang'] = 'de';
        // }
        (window as any).urlObj['lang'] = this.props.intl.locale;

        if (userlist) {
            (window as any).urlObj['userlist'] = userlist;
        }
        if (product) {
            (window as any).urlObj['product'] = product;
        }
        console.log("constructor urlObj: ", (window as any).urlObj);

        this.audiencesObj = {
            'arrival': false,
            'departure': false,
            'intercontinental': false,
            'local': false,
            'non schengen': false,
            'schengen': false
        };


        // console.log("HISTORY REPLACE, constructor", (window as any).urlObj['lang'], languageCode);
        // if (languageCode) {
        //     console.log("will update intl in constructor", languageCode);
        //     props.history.replace({
        //         pathname: `/`,
        //         search: "?" + new URLSearchParams({ ...(window as any).urlObj }).toString()
        //     })
        //     store.dispatch(
        //       updateIntl({
        //         locale: languageCode,
        //         messages: this.props.locales[languageCode]
        //       })
        //     );
        // } else {
        //     props.history.replace({
        //         pathname: `/`,
        //         search: "?" + new URLSearchParams({ ...(window as any).urlObj }).toString()
        //     })
        // }


        if (this.props.location.search && this.props.location.search.length > 0) {
            // const url = new URL(this.props.location.search);
            // const url = new URL(window.location.href);
            modalContentId = getParameterByName('product', this.props.location.search);
            // userList = getParameterByName('userlist', this.props.location.search);
            // //console.log("modalContentId", modalContentId, 'userList', userList);
        }

        this.state = {
            modalIsOpen: modalContentId ? true : false,
            // modalContentId: modalContentId,
            productId: "",
            filterFormats: "",
            filterZones: "",
            filterAudiences: "",
            locationAirside: false,
            filtersExpanded: false,
            locationLandside: false,
            available: false,
            availableSoon: false,
            filterByZonesNo: 0,
            filterByFormatsNo: 0,
            filterByAudiencesNo: 0,
            zones: null,
            formats: null,
            audiences: null,
            price: [0, maxPrice],
            priceMin: 0,
            priceMax: maxPrice,
            showingNowNo: rowsNumber,
            // readyToDisplay: false,
        }
    }
    private subtitle = null;
    private manipulationTimeout = null;
    private scrollableContainer = null;
    private audiencesObj = {};
    private userLang = null;
    // private scrollPosBeforeModal = 0;

    componentDidMount() {
        console.log('search page did mount', this.props.products, this.userLang, (window as any).urlObj['lang']);
        // document.body.classList.remove('backdrop');
        this.clearAllFilters();
        window.addEventListener('scroll', this.handleScroll, true);
        this.props.fetchProducts((window as any).urlObj['lang']).then((res) => {
            //console.log("fetched products",res);
            // this.setState({readyToDisplay: true});
            if ((window as any).urlObj && (window as any).urlObj['userlist'].length) {
                this.props.setSidebar(true);
            }

            // Analytics code
            setTimeout(() => {
                (window as any).dataLayer.push({ 'event': 'virtual_pageview', 'virtual_path': '/airport-product-finder/' });
            }, 1000);
            // (window as any).dataLayer = (window as any).dataLayer || [];
        }).catch(function (err) {
            //console.log('ERROR2', err);
        });
    }

    clearAllFilters() {
        this.props.setFilters({
            name: 'clearAll',
        }).then((res) => {
            //console.log("clearedAll zonesObj", this.props.zonesObj);
            this.setState({
                productId: "",
                filterFormats: "",
                filterZones: "",
                filterAudiences: "",
                locationAirside: false,
                locationLandside: false,
                available: false,
                availableSoon: false,
                filterByZonesNo: 0,
                filterByFormatsNo: 0,
                filterByAudiencesNo: 0,
                zones: this.props.zonesObj,
                formats: this.props.formatsObj,
                audiences: this.audiencesObj,
                price: [0, maxPrice],
                priceMax: maxPrice,
                priceMin: 0,
                showingNowNo: rowsNumber
            })
        });
    }

    handleScroll = () => {
        // if (!this.props.modalId) {
        //     this.scrollPosBeforeModal = window.scrollY || (window as any).scrollTop || document.getElementsByTagName("html")[0].scrollTop;
        // }
        // console.log("handleScroll", this.scrollPosBeforeModal, this.scrollableContainer);
        setTimeout(() => {
            if (this.scrollableContainer) {
                if (document.body.clientHeight + 4 >= this.scrollableContainer.getBoundingClientRect().bottom && !this.props.modalId) {
                    // //console.log("requestAnimationFrame2", this.scrollableContainer.getBoundingClientRect().bottom);
                    if (this.props.loading === false && (this.state.showingNowNo < this.props.products.length)) {
                        this.props.setSpinner(true);
                        setTimeout(() => {
                            this.setState({
                                showingNowNo: this.state.showingNowNo + 10
                            });
                            this.props.setSpinner(false);
                        }, 500)
                    }
                }
            }
        }, 500)
        // lastScrollY = window.scrollY;

        // if (!ticking) {
        // window.requestAnimationFrame(() => {
        // //console.log("requestAnimationFrame", document.body.scrollTop, document.body.clientHeight, document.body.scrollHeight, document.body.getBoundingClientRect().bottom);
        // if (document.body.clientHeight + 2 >= this.scrollableContainer.getBoundingClientRect().bottom && !this.state.modalIsOpen) {
        // // //console.log("requestAnimationFrame2", this.scrollableContainer.getBoundingClientRect().bottom);
        //     if (this.props.loading === false) {
        //         this.props.setSpinner(true);
        //         setTimeout(() => {
        //             this.setState({
        //                 showingNowNo: this.state.showingNowNo + 5
        //             });
        //             this.props.setSpinner(false);
        //         }, 500)
        //     }
        // }
        // ticking = false;
        // });

        // ticking = true;
        // }
    };

    componentWillUnmount() {
        document.body.classList.remove("searchPage");
        window.removeEventListener('scroll', this.handleScroll);
    }

    getSnapshotBeforeUpdate(prevProps, prevState) {
        // Are we adding new items to the list?
        // Capture the scroll position so we can adjust scroll later.
        // //console.log("getSnapshotBeforeUpdate", prevProps, prevState, scrollPos);
        // let currentScrollPos = window.scrollY || (window as any).scrollTop || document.getElementsByTagName("html")[0].scrollTop;
        // //console.log("currentScrollPos", currentScrollPos, this.scrollPosBeforeModal);
        // if (currentScrollPos === 0 && (this.scrollPosBeforeModal > 0) ) {
        //     //console.log("will scroll");
        //     setTimeout(() => {
        //         if (window.scroll.prototype) {
        //             // for edge
        //             document.documentElement.scrollTop = this.scrollPosBeforeModal;
        //         } else {
        //             window.scroll({
        //                 top: this.scrollPosBeforeModal,
        //                 left: 0,
        //                 behavior: 'smooth'
        //             });
        //         }
        //     }, 1);
        // }
        return null;
    }

    static getDerivedStateFromProps(props, state) {
        // Update state so the next render will show the fallback UI.
        let newModalContentId = '';
        let newUserList = [];
        let updatedState = {};
        let languageCode = props.intl.locale;
        let languageCodeFromUrl = getParameterByName('lang', props.location.search);
        let productFromUrl = getParameterByName('product', props.location.search);
        let urlObject = {};


        console.log("getDerivedStateFromProps search:", props.location.search, 'modalId:', props.modalId, 'lang: ', props.intl.locale, languageCode);
        if (props.location.search === '') {
            updatedState['modalIsOpen'] = false;
            if (props.userList.length) {
                console.log("HISTORY REPLACE, derived state userlist");
                urlObject['lang'] = languageCode;
                urlObject['userlist'] = props.userList.join(',');

                props.history.replace({
                    pathname: `/`,
                    search: "?" + new URLSearchParams({ ...urlObject }).toString()
                })
            }
        } else {
            console.log("getDerivedStateFromProps else", props.location.search, props.userList, state);
            if (defined(getParameterByName('userlist', props.location.search))) {
                urlObject['userlist'] = props.userList.join(',');
                urlObject['lang'] = languageCode;
                if (defined(productFromUrl)) {
                    urlObject['product'] = productFromUrl;
                }

                newUserList = getParameterByName('userlist', props.location.search).split(',');
                if (props.userList.length && (newUserList.length !== props.userList.length)) {
                    console.log("HISTORY REPLACE, derived state else");
                    props.history.replace({
                        pathname: `/`,
                        search: "?" + new URLSearchParams({ ...urlObject }).toString()
                    })
                }
            } else {
                newUserList = [];
            }

            if (defined(languageCodeFromUrl)) {
                if (languageCodeFromUrl !== languageCode) {
                    // props.history.replace({
                    //     pathname: `/`,
                    //     search: "?" + new URLSearchParams({'lang': languageCodeFromUrl, 'userlist': props.userList.join(',')}).toString()
                    // })
                    console.log("will update languages from derive state", languageCodeFromUrl, languageCode, (window as any).urlObj);
                    const matched = ['fr', 'it', 'en', 'de'].includes(languageCodeFromUrl);
                    console.log("will update intl in derive statte if", matched);
                    matched && store.dispatch(
                        updateIntl({
                            locale: languageCodeFromUrl,
                            messages: props.locales[languageCodeFromUrl]
                        })
                    );
                }
            }

            if (defined(productFromUrl)) {
                newModalContentId = productFromUrl;
                console.log("product defined:", newModalContentId, props.location.search, props.modalId, state.modalIsOpen);
            } else {
                console.log("product not defined", productFromUrl);
                newModalContentId = '';
                // close modal via redux when user pressed browser back button
                // state.modalIsOpen && props.closeModal();
            }

            updatedState['modalContentId'] = newModalContentId;
            updatedState['modalIsOpen'] = Boolean(newModalContentId.length);

            // opened via redux
            if (props.modalId && !state.modalIsOpen && (newModalContentId !== '')) {
                console.log("props modalId", props.modalId, state.modalIsOpen);
                updatedState['modalIsOpen'] = true;
                updatedState['modalContentId'] = props.modalId;
            }


            // if (props.userList.length) {
            //     props.history.replace({
            //         pathname: `/`,
            //         search: "?" + new URLSearchParams({'userlist': props.userList.join(',')}).toString()
            //     })
            // }
        }
        console.log("derived state", updatedState);
        // let currentScrollPos = window.scrollY || (window as any).scrollTop || document.getElementsByTagName("html")[0].scrollTop;

        return updatedState;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log("searchPage componenentDidUpdate", prevProps, prevState, snapshot);
        if (this.state.formats === null && this.props.formatsObj) {
            this.setState({
                formats: this.props.formatsObj
            })
        }

        if (this.state.zones === null && this.props.zonesObj) {
            this.setState({
                zones: this.props.zonesObj
            })
        }

        if (prevProps.filtersNumber !== this.props.filtersNumber) {
            //console.log("ready to display");
            // this.setState({
            //     readyToDisplay: true
            // })
        }
    }

    openModal = (id) => {
        //console.log("openModal", id, 'userList', this.props.userList);
        // this.scrollPosBeforeModal = window.scrollY || (window as any).scrollTop || document.getElementsByTagName("html")[0].scrollTop;
        // this.setState({modalIsOpen: true, modalContentId: id});
        this.props.openModal(id);
        this.setState({
            modalIsOpen: true
        })
    }

    fakeLoading(preventAnimation?) {
        if (preventAnimation) {
            this.props.setSpinner(true);
            setTimeout(() => {
                this.props.setSpinner(false);
            }, 1500)
        } else {
            document.body.classList.add('loading');
            this.props.setSpinner(true);
            setTimeout(() => {
                document.body.classList.remove('loading');
                this.props.setSpinner(false);
            }, 1500)
        }
    }

    changeLanguage = (languageCode) => {
        console.log("changeLanguage in searchpage", languageCode);
        const productFromUrl = getParameterByName('product');

        store.dispatch(
            updateIntl({
                locale: languageCode,
                messages: this.props.locales[languageCode]
            })
        );

        if (/*this.props.modalId*/productFromUrl) {
            console.log("HISTORY REPLACE, changeLanguage");
            this.props.history.replace({
                pathname: `/`,
                search: "?" + new URLSearchParams({ 'lang': languageCode, 'product': productFromUrl/*this.props.modalId.toString()*/, 'userlist': this.props.userList.join(',') }).toString()
            })
        } else {
            console.log("HISTORY REPLACE, changeLanguage");
            this.props.history.replace({
                pathname: `/`,
                search: "?" + new URLSearchParams({ 'lang': languageCode, 'userlist': this.props.userList.join(',') }).toString()
            })
        }
    }

    closeModal = () => {
        //console.log("close modal");
        this.setState({ modalIsOpen: false });
        if (this.props.userList.length) {
            console.log("HISTORY REPLACE, closeModal");
            this.props.history.push({
                pathname: `/`,
                search: "?" + new URLSearchParams({ 'lang': this.props.intl.locale, 'userlist': this.props.userList.join(',') }).toString()
            })

        } else {
            console.log("HISTORY REPLACE, closeModal");
            this.props.history.push({
                pathname: `/`,
                search: "?" + new URLSearchParams({ 'lang': this.props.intl.locale })
            })

        }
        this.props.closeModal();
    }

    productIdChanged(name, value) {
        clearTimeout(this.manipulationTimeout);
        //console.log("productIdChanged", name, value, this.state.productId);
        this.setState({
            productId: value.toString(),
            // readyToDisplay: false
        }, () => {
        });

        this.manipulationTimeout = setTimeout(() => {
            // if (value.toString().length) {
            this.props.setFilters({
                value: value.toString(),
                name: name,
                filtersNo: (value.toString().length ? 1 : 0)
            });
            // }
            this.fakeLoading();
        }, 1000);
    }

    calcLocationFiltersNo = () => {
        let counter = 0;
        if (this.state.locationAirside) {
            counter += 1;
        }
        if (this.state.locationLandside) {
            counter += 1;
        }
        return counter;
    }

    onLandsideLocationChange(name, val) {
        //console.log("onLandsideLocationChange", name, val);
        this.setState({
            locationLandside: val,
            // readyToDisplay: false
        }, () => {
            this.props.setFilters({
                value: { 'locationAirside': this.state.locationAirside, 'locationLandside': this.state.locationLandside },
                name: name,
                filtersNo: this.calcLocationFiltersNo()
            });
        });
        this.fakeLoading();
    }

    onAirsideLocationChange(name, val) {
        //console.log("onAirsideLocationChange", name, val);
        this.setState({
            locationAirside: val,
            // readyToDisplay: false
        }, () => {
            this.fakeLoading();
            this.props.setFilters({
                value: { 'locationAirside': this.state.locationAirside, 'locationLandside': this.state.locationLandside },
                name: name,
                filtersNo: this.calcLocationFiltersNo()
            });
        });
    }

    onAudienceChange(name, val) {
        console.log("onAudienceChange", name, val);

        if (val) {
            this.setState({
                audiences: { ...this.state.audiences, [name]: val },
                filterByAudiencesNo: this.state.filterByAudiencesNo + 1,
                // readyToDisplay: false
            }, () => {
                this.fakeLoading();
                this.props.setFilters({
                    value: this.state.audiences,
                    name: name,
                    filterCriteria: 'audience',
                    filtersNo: this.state.filterByAudiencesNo
                });
            });
        } else {
            this.setState({
                audiences: { ...this.state.audiences, [name]: val },
                filterByAudiencesNo: this.state.filterByAudiencesNo - 1,
                // readyToDisplay: false
            }, () => {
                this.fakeLoading();
                this.props.setFilters({
                    value: this.state.audiences,
                    name: name,
                    filterCriteria: 'audience',
                    filtersNo: this.state.filterByAudiencesNo
                });
            });
        }
    }

    onAvailableChange(name, val) {
        //console.log("onAudienceChange", name, val);
        this.setState({
            available: val,
            // readyToDisplay: false
        }, () => {
            this.fakeLoading();
            this.props.setFilters({
                value: val,
                name: name,
                filtersNo: ((this.state.available || this.state.availableSoon) ? 1 : 0)
            });
        });
    }

    onAvailableSoonChange(name, val) {
        //console.log("onAudienceChange", name, val);
        this.setState({
            availableSoon: val,
            // readyToDisplay: false
        }, () => {
            this.fakeLoading();
            this.props.setFilters({
                value: val,
                name: name,
                filtersNo: ((this.state.available || this.state.availableSoon) ? 1 : 0)
            });
        });
    }

    isAnyTrue(obj) {
        let val = 0;
        //console.log("is any true in ", obj);
        Object.keys(obj).forEach((key) => {
            if (obj[key]) {
                val += 1
            }
        })
        return val;
    }

    onFormatsChange(name, val) {
        //console.log("onFormatsChange", name, val);

        if (val) {
            this.setState({
                filterByFormatsNo: this.state.filterByFormatsNo + 1,
                formats: { ...this.state.formats, [name]: val },
                // readyToDisplay: false
                // formats: newFormats
            }, () => {
                this.fakeLoading();
                this.props.setFilters({
                    value: val,
                    name: name,
                    filterCriteria: 'format',
                    filtersNo: this.isAnyTrue(this.state.formats)
                });
            })
        } else {
            this.setState({
                filterByFormatsNo: this.state.filterByFormatsNo - 1,
                formats: { ...this.state.formats, [name]: val },
                // readyToDisplay: false
                // formats: newFormats
            }, () => {
                this.fakeLoading();
                this.props.setFilters({
                    value: val,
                    name: name,
                    filterCriteria: 'format',
                    filtersNo: this.isAnyTrue(this.state.formats)
                });
            })
        }

    }

    onZonesChange(name, val) {
        //console.log("onZonesChange", name, val);

        if (val) {
            this.setState({
                filterByZonesNo: this.state.filterByZonesNo + 1,
                zones: { ...this.state.zones, [name]: val },
                // readyToDisplay: false
            }, () => {
                this.fakeLoading();

                this.props.setFilters({
                    value: val,
                    name: name,
                    filterCriteria: 'zone',
                    filtersNo: this.isAnyTrue(this.state.zones)
                });
            })
        } else {
            this.setState({
                filterByZonesNo: this.state.filterByZonesNo - 1,
                zones: { ...this.state.zones, [name]: val },
                // readyToDisplay: false
                // zones: newZones
            }, () => {
                this.fakeLoading();

                this.props.setFilters({
                    value: val,
                    name: name,
                    filterCriteria: 'zone',
                    filtersNo: this.isAnyTrue(this.state.zones)
                });
            })
        }
    }

    onPriceChanged(name, val) {
        //console.log("onpricechanged", name, val);

        this.fakeLoading();
        //Range
        this.setState({
            price: val,
            priceMax: Number(val[1]),
            priceMin: Number(val[0]),
            // readyToDisplay: false
        }, () => {
            // this.props.setFilters({
            //     value: this.state.price,
            //     name: 'priceRange',
            //     filterCriteria: 'price'
            // });
        });

        this.props.setFilters({
            value: val,
            name: 'priceRange',
            filterCriteria: 'price'
        });
    }

    onPriceBlur(name, val) {
        //console.log("onpriceblur", name, val);
        clearTimeout(this.manipulationTimeout);
        this.manipulationTimeout = setTimeout(() => {
            this.fakeLoading();
            if (name === "priceMin") {
                this.setState({
                    'priceMin': Number(val),
                    price: [Number(val), this.state.price[1]],
                    // readyToDisplay: false
                }, () => {
                    this.props.setFilters({
                        value: this.state.price,
                        name: 'priceRange',
                        filterCriteria: 'price'
                    });
                });
            } else if (name === "priceMax") {
                this.setState({
                    'priceMax': Number(val),
                    price: [this.state.price[0], Number(val)],
                    // readyToDisplay: false
                }, () => {
                    this.props.setFilters({
                        value: this.state.price,
                        name: 'priceRange',
                        filterCriteria: 'price'
                    });
                });
            }
        }, 1000);
    }

    renderFilters() {
        return (
            <div className={this.state.filtersExpanded ? "filters expanded" : "filters"}>
                <div className="paddingContainer">
                    <h2 className="filterTitle d-none d-lg-block">
                        <FormattedMessage id="app.filteroffering" defaultMessage="ANGEBOT FILTEREN" />
                        {(this.props.filtersNumber > 0) && "(" + this.props.filtersNumber + ")"}</h2>
                    <Button
                        className="clearAllButton w-auto"
                        onClick={() => { this.fakeLoading(); this.clearAllFilters(); }}
                        buttonType={EButtonType.BasicLink}
                    >
                        <FormattedMessage id="app.clear" defaultMessage="Alle Filter löschen" />
                    </Button>

                    {/*this.props.loading && <Spinner small/>*/}
                    <div className="breaker"></div>
                    <div className="formContainer">
                        <div className="formTitle bordered"><FormattedMessage id="app.price" defaultMessage="Preis (CHF)" /></div>
                        {/*value={this.state.price}  onChange={(val) => this.onPriceChange('priceRange', val)}*/}
                        <InputSlider min={0} max={maxPrice} range onAfterChange={(val) => this.onPriceChanged('priceRangeAfter', val)} priceMin={this.state.priceMin} priceMax={this.state.priceMax} />
                        <div className="d-flex">
                            <Input
                                name="priceMin"
                                inputWrapperClass="w-50"
                                placeholder="Min"
                                defaultValue={'0'}
                                value={this.state.priceMin}
                                onBlur={(name, value) => this.onPriceBlur(name, value)}
                                onChange={(name, value) => this.onPriceBlur(name, value)}
                                validationMessageConfig={null/* this.validationMessageConfig() */}
                                type='text'
                                validationTests={[EValidationTestType.hasValue]}
                                inputWrapperStyle={{}}></Input>
                            <Input
                                name="priceMax"
                                defaultValue={maxPrice}
                                inputWrapperClass="w-50 ml-4"
                                placeholder="Max"
                                value={this.state.priceMax}
                                onChange={(name, value) => this.onPriceBlur(name, value)}
                                validationMessageConfig={null/* this.validationMessageConfig() */}
                                type='text'
                                validationTests={[EValidationTestType.hasValue]}
                                inputWrapperStyle={{}}></Input>
                        </div>
                        <div className="disclaimer">
                            <FormattedMessage id="app.disclaimer" defaultMessage="Preisänderungen und Verfügbarkeiten vorbehalten. Alle Preise verstehen sich exkl. MwSt." />
                        </div>
                    </div>

                    <div className="formContainer">
                        <div className={this.state.formats && this.state.formats.length > 4 ? "formTitle" : "formTitle bordered"}><FormattedMessage id="app.format" defaultMessage="Format" /> ({this.state.formats && Object.keys(this.state.formats).length})</div>
                        {this.state.formats && this.state.formats.length > 4 && <Input
                            name="filterFormats"
                            inputWrapperClass="w-100"
                            placeholder={this.props.intl.formatMessage({ id: "app.textein", defaultMessage: "Text eingeben" })}
                            value={this.state.filterFormats}
                            onChange={(name, value) => this.setState({ filterFormats: value })}
                            validationMessageConfig={null/* this.validationMessageConfig() */}
                            type='text'
                            validationTests={[EValidationTestType.hasValue]}
                            inputWrapperStyle={{}}></Input>}
                        <div className="checkboxContainer styledScrollbar">
                            {this.props.formatsObj && this.state.formats && Object.keys(this.props.formatsObj).sort().map((key, idx) => {
                                return RegExp(this.state.filterFormats, 'gi').test(key) && <Input
                                    key={"format" + idx}
                                    checked={this.state.formats[key]}
                                    checkboxLabel={key}
                                    name={key}
                                    onChange={(name, value) => this.onFormatsChange(name, value)}
                                    type='checkbox'
                                    inputWrapperClass=""
                                    inputWrapperStyle={{
                                        display: 'inline-block',
                                        width: 'auto',
                                        height: '30px',
                                        marginTop: '0',

                                    }}>
                                </Input>
                            })}
                        </div>
                    </div>

                    <div className="formContainer">
                        <div className="formTitle bordered"><FormattedMessage id="app.availability" defaultMessage="Verfügbarkeit" /></div>
                        {[this.props.intl.formatMessage({ id: "app.available", defaultMessage: "Verfügbar" }), this.props.intl.formatMessage({ id: "app.onrequest", defaultMessage: "auf Anfrage" })].sort().map((word, idx) => {
                            if (word === this.props.intl.formatMessage({ id: "app.available", defaultMessage: "Verfügbar" })) {
                                return <Input
                                    key={"availabilityInput" + idx}
                                    checked={this.state.available}
                                    checkboxLabel={this.props.intl.formatMessage({ id: "app.available", defaultMessage: "Verfügbar" })}
                                    name="available"
                                    onChange={(name, value) => this.onAvailableChange(name, value)}
                                    type='checkbox'
                                    inputWrapperClass=""
                                    inputWrapperStyle={{
                                        display: 'inline-block',
                                        width: 'auto',
                                        height: '30px',
                                        marginTop: '0',

                                    }}>
                                </Input>
                            } else if (word === this.props.intl.formatMessage({ id: "app.onrequest", defaultMessage: "auf Anfrage" })) {
                                return <Input
                                    key={"availabilityInput" + idx}
                                    checked={this.state.availableSoon}
                                    checkboxLabel={this.props.intl.formatMessage({ id: "app.onrequest", defaultMessage: "auf Anfrage" })}
                                    name="availableSoon"
                                    onChange={(name, value) => this.onAvailableSoonChange(name, value)}
                                    type='checkbox'
                                    inputWrapperClass=""
                                    inputWrapperStyle={{
                                        display: 'inline-block',
                                        width: 'auto',
                                        height: '30px',
                                        marginTop: '0',

                                    }}>
                                </Input>
                            }
                        })}
                    </div>
                    <div className="formContainer">
                        <div className="formTitle bordered"><FormattedMessage id="app.area" defaultMessage="Bereich" /></div>
                        {[this.props.intl.formatMessage({ id: "app.landside", defaultMessage: "Landseite" }), this.props.intl.formatMessage({ id: "app.airside", defaultMessage: "Luftseite" })].sort().map((word, idx) => {
                            if (word === this.props.intl.formatMessage({ id: "app.landside", defaultMessage: "Landseite" })) {
                                return <Input
                                    key={"areaInput" + idx}
                                    checked={this.state.locationLandside}
                                    checkboxLabel={this.props.intl.formatMessage({ id: "app.landside", defaultMessage: "Landseite" })}
                                    name="landside"
                                    onChange={(name, value) => this.onLandsideLocationChange(name, value)}
                                    type='checkbox'
                                    inputWrapperClass=""
                                    inputWrapperStyle={{
                                        display: 'inline-block',
                                        width: 'auto',
                                        height: '30px',
                                        marginTop: '0',

                                    }}>
                                </Input>
                            } else if (word === this.props.intl.formatMessage({ id: "app.airside", defaultMessage: "Luftseite" })) {
                                return <Input
                                    key={"areaInput" + idx}
                                    checked={this.state.locationAirside}
                                    checkboxLabel={this.props.intl.formatMessage({ id: "app.airside", defaultMessage: "Luftseite" })}
                                    name="airside"
                                    onChange={(name, value) => this.onAirsideLocationChange(name, value)}
                                    type='checkbox'
                                    inputWrapperClass=""
                                    inputWrapperStyle={{
                                        display: 'inline-block',
                                        width: 'auto',
                                        height: '30px',
                                        marginTop: '0',

                                    }}>
                                </Input>
                            }
                        })}
                    </div>
                    <div className="formContainer">
                        <div className="formTitle"><FormattedMessage id="app.zones" defaultMessage="Zonen" /> ({this.state.zones && Object.keys(this.state.zones).length})</div>
                        <Input
                            name="filterZones"
                            inputWrapperClass="w-100"
                            placeholder={this.props.intl.formatMessage({ id: "app.textein", defaultMessage: "Text eingeben" })}
                            value={this.state.filterZones}
                            onChange={(name, value) => this.setState({ filterZones: value })}
                            validationMessageConfig={null/* this.validationMessageConfig() */}
                            type='text'
                            validationTests={[EValidationTestType.hasValue]}
                            inputWrapperStyle={{}}></Input>
                        <div className="checkboxContainer styledScrollbar">
                            {this.props.zonesObj && this.state.zones && Object.keys(this.props.zonesObj).sort().map((key, idx) => {
                                return RegExp(this.state.filterZones, 'gi').test(key) && <Input
                                    key={"zone" + idx}
                                    checked={this.state.zones[key]}
                                    checkboxLabel={key}
                                    name={key}
                                    onChange={(name, value) => this.onZonesChange(name, value)}
                                    type='checkbox'
                                    inputWrapperClass=""
                                    inputWrapperStyle={{
                                        display: 'inline-block',
                                        width: 'auto',
                                        height: '30px',
                                        marginTop: '0',

                                    }}>
                                </Input>
                            })}
                        </div>
                    </div>
                    <div className="formContainer">
                        <div className="formTitle"><FormattedMessage id="app.audience" defaultMessage="Zielpublikum" /> ({this.state.audiences && Object.keys(this.state.audiences).length})</div>
                        {this.state.audiences && Object.keys(this.audiencesObj).length > 4 && <Input
                            name="filterAudience"
                            inputWrapperClass="w-100"
                            value={this.state.filterAudiences}
                            placeholder={this.props.intl.formatMessage({ id: "app.textein", defaultMessage: "Text eingeben" })}
                            onChange={(name, value) => this.setState({ filterAudiences: value })}
                            validationMessageConfig={null/* this.validationMessageConfig() */}
                            type='text'
                            validationTests={[EValidationTestType.hasValue]}
                            inputWrapperStyle={{}}></Input>}
                        <div className="checkboxContainer styledScrollbar">
                            {this.state.audiences && Object.keys(this.audiencesObj).sort().map((key, idx) => {
                                return RegExp(this.state.filterAudiences, 'gi').test(key) && <Input
                                    checked={this.state.audiences[key]}
                                    checkboxLabel={key}
                                    name={key}
                                    key={"audienceInput" + idx}
                                    onChange={(name, value) => this.onAudienceChange(name, value)}
                                    type='checkbox'
                                    inputWrapperClass=""
                                    inputWrapperStyle={{
                                        display: 'inline-block',
                                        width: 'auto',
                                        height: '30px',
                                        marginTop: '0',

                                    }}>
                                </Input>
                            })}
                        </div>
                    </div>
                    <div className="formContainer">
                        <div className="formTitle"><FormattedMessage id="app.searchbyid" defaultMessage="Suche nach ID" /></div>
                        <Input
                            id="productIdInput"
                            name="productId"
                            inputWrapperClass="w-100"
                            placeholder={this.props.intl.formatMessage({ id: "app.textein", defaultMessage: "Text eingeben" })}
                            value={this.state.productId}
                            onChange={(name, value) => this.productIdChanged(name, value)}
                            validationMessageConfig={null/* this.validationMessageConfig() */}
                            type='text'
                            validationTests={[EValidationTestType.hasValue]}
                            inputWrapperStyle={{}}></Input>
                    </div>
                </div>
                <div className="paddingContainer"></div>
            </div>)
    }

    goToSearch() {
        this.props.closeModal();
        this.setState({
            modalIsOpen: false
        })
        if (this.props.userList.length) {
            console.log("HISTORY PUSH, goToSearch");
            this.props.history.push({
                pathname: `/`,
                search: "?" + new URLSearchParams({ 'lang': this.props.intl.locale, 'userlist': this.props.userList.join(',') }).toString()
            })
        } else {
            console.log("HISTORY PUSH, goToSearch");
            this.props.history.push({
                pathname: `/`,
                search: "?" + new URLSearchParams({ 'lang': this.props.intl.locale }).toString()
            })
        }
    }

    isPresentOnTheList(id) {
        if (isXInArray(id.toString(), this.props.userList)) {
            //console.log("willupdatestate");
            return true;
        } else {
            return false;
        }
    }

    renderModal() {
        //console.log("renderModal", this.scrollPosBeforeModal);
        const modalProduct = this.props.productsObj[this.props.modalId];
        return (
            <div className="modalContent">
                {this.props.loading && <Spinner sticky />}
                <Header productPage locales={this.props.locales} language={this.props.intl.locale} changeLanguage={this.changeLanguage} />
                <div className="modalContentWarpper">
                    <div className="container-fluid px-0">
                        <Button
                            className="text-uppercase w-auto pl-4 ml-3 my-5 my-lg-0 backToSearchButton"
                            submit={true}
                            onClick={() => this.goToSearch()}
                            buttonType={EButtonType.BasicLink}
                        >
                            <span className="arrow left">
                                <IconArrow className="fillMain strokeMain" />
                            </span>
                            <FormattedMessage id="app.backtosearch" defaultMessage="Zurück zur Suche" />
                        </Button>
                        <Button
                            className="text-uppercase mb-5 col-6 yourListButton floatRight"
                            onClick={() => this.props.setSidebar(true)}
                            buttonType={EButtonType.PillMedium}
                        >
                            <FormattedMessage id="app.userlist" defaultMessage="MERKLISTE" /> <span className="colorMain numberOfProducts" >{this.props.userList.length}</span>
                        </Button>

                        {modalProduct && <ProductRowLarge
                            adPrice={modalProduct.ad_price}
                            addToList={this.props.addToList}
                            airportInfo={modalProduct.additional_info_airport}
                            arrival={modalProduct.is_audience_arrival_area}
                            available={Boolean(modalProduct.availability === 1)}
                            availableSince={modalProduct.becomes_available_at}
                            billboardImage={modalProduct.billboard_image}
                            button_under_detail_label={modalProduct.button_under_detail_label}
                            button_under_detail_link={modalProduct.button_under_detail_link}
                            button_under_detail_2_label={modalProduct.button_under_detail_2_label}
                            button_under_detail_2_link={modalProduct.button_under_detail_2_link}
                            className={'p-0'}
                            departure={modalProduct.is_audience_departure_area}
                            dimensions={modalProduct.dimensions}
                            format={modalProduct.format && modalProduct.format.name}
                            formatDetail={modalProduct.format && modalProduct.format.format_detail}
                            id={modalProduct.id}
                            idExt={modalProduct.id_external}
                            intercontinental={modalProduct.is_audience_intercontinental_flight}
                            internal={modalProduct.is_audience_local_flight}
                            isDigital={modalProduct.medium === 1}
                            isPresentOnTheList={this.isPresentOnTheList(modalProduct.id)}
                            locationAirside={modalProduct.is_airside}
                            locationLandside={modalProduct.is_landside}
                            locationMapImage={modalProduct.location_map_image}
                            nonschengen={modalProduct.is_audience_non_schengen_flight}
                            planImage={modalProduct.plan_image}
                            pricingType={modalProduct.pricing_type}
                            productionAndDelivery={modalProduct.production_and_delivery}
                            productionPrice={modalProduct.production_price}
                            removeFromList={this.props.removeFromList}
                            schengen={modalProduct.is_audience_schengen_flight}
                            setupPriceDigital={modalProduct.setup_price_digital}
                            setupPricePrint={modalProduct.setup_price_print}
                            zone={modalProduct.zone && modalProduct.zone.name}
                            zoneDetail={modalProduct.zone && modalProduct.zone.zone_detail}
                            zoneInfo={modalProduct.zone && modalProduct.zone.additional_info}
                        />}
                    </div>
                </div>
            </div>
        )
    }

    afterOpenModal() {
        //console.log("afterOpenModal");
    }

    render() {
        return (<div className="searchPageContainer">
            <Header locales={this.props.locales} language={this.props.intl.locale} changeLanguage={this.changeLanguage}>
            </Header>

            <Modal isOpen={Boolean(this.state.modalIsOpen)} onAfterOpen={this.afterOpenModal} onRequestClose={this.closeModal} contentLabel="Example Modal" style={{
                content: {
                    border: 'none',
                    background: 'transparent',
                    right: 0,
                    bottom: 0,
                    top: 0,
                    left: 0
                }
            }}>
                {this.renderModal()}
            </Modal>

            <div className="contentContainer finderPage d-flex" ref={div => (this.scrollableContainer = div)}>
                <div className="container-fluid w-100 px-0" style={{}}>
                    <div className="w-100 jumboText">
                        <h1 className="headerText px-4">
                            {/*(this.props.filtersNumber > 0) && "(" + this.props.filtersNumber + ")"*/}
                            <FormattedMessage id="app.werbung1" defaultMessage="Werbung in einem exklusiven, internationalen Umfeld" />
                        </h1>
                        <p className="headerParagraph px-4 d-none d-md-flex">
                            <FormattedMessage id="app.werbung2" defaultMessage="Exklusive Kommunikationswelten begeistern Passagiere und Besucher im hochwertigen und kosmopolitischen Umfeld des Flughafens Zürich." />
                        </p>
                    </div>
                    <div className="customContainer">

                        <div className="col-12 justify-content-between angebotRow mb-5 d-flex d-lg-none" style={{ maxHeight: "120px" }}>
                            <div className="col-5 col-sm-6 px-0 d-flex flex-wrap">
                                <h2 className="colorText text-left mr-4"><FormattedMessage id="app.offering" defaultMessage="ANGEBOT" /></h2>
                                <div className="showingResultsNo w-100">
                                    {this.props.products.length} <FormattedMessage id="app.results" defaultMessage="Treffer" />
                                </div>
                            </div>
                            <Button
                                className="text-uppercase mb-0 col-7 col-sm-6 yourListButton mx-auto ml-lg-auto mr-lg-0 align-self-start maxHnone"
                                onClick={() => this.props.setSidebar(true)}
                                buttonType={EButtonType.PillMedium}
                            >
                                <FormattedMessage id="app.userlist" defaultMessage="MERKLISTE" /> <span className="colorMain numberOfProducts">{this.props.userList.length}</span>
                            </Button>
                        </div>
                        <div className="col-12 col-lg-3 filterCol">
                            <Button
                                className="text-uppercase mt-md-5 mb-4 d-block d-lg-none pr-4 text-left pl-3"
                                onClick={() => {
                                    this.setState({
                                        filtersExpanded: !this.state.filtersExpanded
                                    })
                                }
                                }
                                buttonType={EButtonType.InvertedPillMedium}
                            >
                                <FormattedMessage id="app.filteroffering" defaultMessage="ANGEBOT FILTEREN" />{(this.props.filtersNumber > 0) && "(" + this.props.filtersNumber + ")"}
                                <span className="strokeMain" style={{ float: 'right' }}>
                                    {this.state.filtersExpanded ? <IconMinus className="fillMain strokeMain" /> : <IconPlus className="fillMain strokeMain" />}
                                </span>
                            </Button>
                            {this.renderFilters()}
                        </div>
                        <div className="col-12 col-lg-9 d-flex flex-wrap" style={{ alignContent: "flex-start", maxWidth: '1032px' }}>
                            <div className="w-100 justify-content-between angebotRow mb-5 d-none d-lg-flex" style={{ maxHeight: "120px" }}>
                                <div className="col-lg-8 col-12 px-0 d-flex">
                                    <h2 className="colorText text-left mt-2"><FormattedMessage id="app.offering" defaultMessage="ANGEBOT" /></h2>
                                    <div className="showingResultsNo w-100 ml-3">
                                        {this.props.products.length} <FormattedMessage id="app.results" defaultMessage="Treffer" />
                                    </div>
                                </div>
                                <Button
                                    className="text-uppercase mb-0 yourListButton mx-auto ml-lg-auto mr-lg-0"
                                    onClick={() => this.props.setSidebar(true)}
                                    buttonType={EButtonType.PillMedium}
                                >
                                    <FormattedMessage id="app.userlist" defaultMessage="MERKLISTE" /> <span className="colorMain numberOfProducts" >{this.props.userList.length}</span>
                                </Button>
                            </div>
                            {this.props.loading && <Spinner sticky />}
                            {<ProductList
                                products={this.props.products}
                                showingNowNo={this.state.showingNowNo}
                                openModal={this.openModal}
                                addToList={this.props.addToList}
                                removeFromList={this.props.removeFromList}
                                productId={this.state.productId}
                                filterByAudiencesNo={this.state.filterByAudiencesNo}
                                locationAirside={this.state.locationAirside}
                                locationLandside={this.state.locationLandside}
                                available={this.state.available}
                                availableSoon={this.state.availableSoon}
                                filterByZonesNo={this.state.filterByZonesNo}
                                filterByFormatsNo={this.state.filterByFormatsNo}
                                priceMax={this.state.priceMax}
                                priceMin={this.state.priceMin}
                                userList={this.props.userList}
                            />}
                            <div className={this.props.products.length > this.state.showingNowNo && !this.props.loading ? "scrollToLoadMore visible" : "scrollToLoadMore"} style={{ visibility: 'hidden' }}>
                                <div className="iconWrapper large pointer" onClick={() => {
                                    if (this.props.loading === false && (this.state.showingNowNo < this.props.products.length)) {
                                        this.props.setSpinner(true);
                                        setTimeout(() => {
                                            this.setState({
                                                showingNowNo: this.state.showingNowNo + 10
                                            });
                                            this.props.setSpinner(false);
                                        }, 500)
                                    }
                                }}>
                                    <IconArrow className="fillMain" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {gtmId && <GoogleTagManager gtmId={gtmId} key="gaTag" />}
        </div>);
    }
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        products: state.app.products,
        sidebarOpened: state.app.sidebarOpened,
        userList: state.app.userList,
        loading: state.app.loading,
        productsObj: state.app.productsObj,
        zonesObj: state.app.zonesObj,
        formatsObj: state.app.formatsObj,
        filtersNumber: state.app.filtersNumber,
        modalId: state.app.modalId,
        locales: state.locales,
        // language: state.intl.locale
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        setSidebar: (val) => dispatch(actions.setSidebar(val)),
        setUserList: (val) => dispatch(actions.setUserList(val)),
        setFilters: (val) => dispatch(actions.setFilters(val)),
        setSpinner: (val) => dispatch(actions.setSpinner(val)),
        fetchProducts: (val) => dispatch(actions.fetchProducts(val)),
        closeModal: (val) => dispatch(actions.closeModal()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SearchPage));
