/**
 * Renders the appropriate content depending if the predicate is true or false
 */

import { IDictionary } from '../models';

export function renderIf(
  condition: boolean,
  content: {
    ifTrue: () => JSX.Element | JSX.Element[];
    ifFalse: () => JSX.Element | JSX.Element[];
  }
): JSX.Element | JSX.Element[] {
  if (condition) {
    return content.ifTrue();
  } else {
    return content.ifFalse();
  }
}

/**
 * Renders the given content only if the condition is true
 */
export function renderIfTrue(
  condition: boolean,
  content: () => JSX.Element
): JSX.Element | JSX.Element[] | null {
  if (condition) {
    return content();
  }
  return null;
}

/**
 * Renders the given content only if the condition is false
 */
export function renderIfFalse(
  condition: boolean,
  content: () => JSX.Element | JSX.Element[]
): JSX.Element | JSX.Element[] | null {
  if (!condition) {
    return content();
  }
  return null;
}

/**
 * Renders the appropriate content depending if the variable is defined or not
 * @param data      Data variable to check if is defined & not null
 * @param content   The content to render
 */
export function renderDefinedTrue<T>(
  data: T,
  content: (data: T) => JSX.Element
): JSX.Element | null {
  if (typeof data !== 'undefined' && data !== null) {
    return content(data);
  } else {
    return null;
  }
}

/**
 * Renders the appropriate content depending if the variable is defined or not
 * @param data      Data variable to check if is defined & not null
 * @param content   The content to render
 */
export function renderDefined<T>(
  data: T,
  content: {
    isDefined: (data: T) => JSX.Element | JSX.Element[];
    isNotDefined: () => JSX.Element | JSX.Element[];
  }
): JSX.Element | JSX.Element[] {
  if (typeof data !== 'undefined' && data !== null) {
    return content.isDefined && content.isDefined(data);
  } else {
    return content.isNotDefined && content.isNotDefined();
  }
}

/**
 * Renders a callback from the callbacks dictionary based on a key (similar to the switch statement)
 * @param key               Key of the callback to call
 * @param callbacks         All the available callbacks
 * @param defaultCallback   Available if nothing else matches
 */
export function renderSwitch(
  key: string | number,
  callbacks: IDictionary<() => JSX.Element>,
  defaultCallback?: () => JSX.Element
): JSX.Element | null {
  if (callbacks[key]) {
    return callbacks[key]();
  } else {
    if (defaultCallback) {
      return defaultCallback();
    } else {
      return null;
    }
  }
}

export function parseDate(str, type?) {
    if (str) {
        var options = {};

        if (type === 'hr-min') {
            options = {
                // day: 'numeric',
                // month: '2-digit',
                // year: 'numeric',
                hour: '2-digit',
                minute: '2-digit'
            }
            return new Date(Date.parse(str)).toLocaleTimeString('de-DE', options);
        } else {
            options = {
                day: 'numeric',
                month: '2-digit',
                year: 'numeric',
                // hour: '2-digit',
                // minute: '2-digit'
            }
            return new Date(Date.parse(str)).toLocaleDateString('de-DE', options);
        }
    } else
        return ''
};

export function parseNumber(num, afterCommaDigits, languageTag) {
    // //console.log("parseNumber", num, afterCommaDigits, languageTag, parseFloat(num));
    return parseFloat(num).toLocaleString('en', {maximumFractionDigits: afterCommaDigits, minimumFractionDigits: afterCommaDigits})
}

export function formatNum(num, separator, fraction) {
  var str = num.toLocaleString('en-US');
  str = str.replace(/\./, fraction);
  str = str.replace(/,/g, separator);
  return str;
}
