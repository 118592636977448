import { IDictionary } from '../models';
import {
  EValidationMessageType,
  ValidationMessageProps
} from '../components/ValidationMessage/index';

// Tests //

export enum EValidationTestType {
  hasValue = 'hasValue',
  acceptAGBS = 'acceptAGBS',
  isPasswordMatch = 'isPasswordMatch',
  isEmail = 'isEmail',
  isPhoneNumber = 'isPhoneNumber',
  isPassword = 'isPassword',
  isPin = 'isPin'
}

// Test Messages //
export const VALIDATION_MESSAGE: IDictionary<ValidationMessageProps> = {
  hasValue: {
    message: 'Pflichtfeld darf nicht leer sein',
    type: EValidationMessageType.Error
  },
  isPasswordMatch: {
    message: 'Passwörter stimmen nicht überein', // tslint:disable-line
    type: EValidationMessageType.Error
  },
  isEmail: {
    message: 'E-Mail ist ungültig',
    type: EValidationMessageType.Error
  },
  isPhoneNumber: {
    message: 'Telefonnummer ist ungültig',
    type: EValidationMessageType.Error
  },
  isPin: {
    message: 'PIN ist ungültig',
    type: EValidationMessageType.Error
  },
  isPassword: {
    message:
      'Das Passwort muss mindestens 8 Zeichen lang sein und muss mindestens 1 Sonderzeichen enthalten.',
    type: EValidationMessageType.Error
  }
};
