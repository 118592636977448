import * as constants from '../constants';
import de from '../constants/locales/de.json';
import en from '../constants/locales/en.json';
import fr from '../constants/locales/fr.json';
import it from '../constants/locales/it.json';

// export interface LocalesState {
    // sidebarOpened: boolean;
// }
//console.log("getParameterByName('userlist').split(',')", getParameterByName('userlist'), window.location.href );


const getInitialState = () => {
    return {
        en: en,
        it: it,
        de: de,
        fr: fr
    }
};

// console.log("app.ts", de);

const localesReducer = (state: any = getInitialState(), action: any) => {
    switch (action.type) {
        case constants.UPDATE_LOCALES: {
            console.log("update locales", action);
            return { ...state, ...action.payload };
        }
    }
    return state;
};

export default localesReducer;
