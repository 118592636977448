import * as constants from '../constants';
import {getParameterByName} from '../utils/toSearchParams';
import {ComparatorDual} from '../utils/sorting';
import {/*convertToOjbect, */removeInArray} from '../utils/array';
// const products = require('../data/apg.json');


export interface AppState {
    sidebarOpened: boolean;
    productsAll: Array<any>;
    products: Array<any>;
    productsForFiltering: Array<any>;
    userList: Array<any>;
    productsObj: any;
    modalId: any;
    filterByPriceActive: boolean,
    filterByFormatActive: boolean,
    filterByAvailabilityActive: boolean,
    filterByAudienceActive: boolean,
    filterByLocationsActive: boolean,
    filterByZonesActive: boolean,
    filterByIdActive: boolean,
    filtersNumber: number,
    loading: boolean;
    lastError: any;
    zonesObj: any;
    formatsObj: any;
}
//console.log("getParameterByName('userlist').split(',')", getParameterByName('userlist'), window.location.href );


const getInitialState = () => {
    return {
        // sidebarOpened: getParameterByName('userlist') ? true : false,
        sidebarOpened: false,
        loading: false,
        products: [],
        productsForFiltering: [],
        productsAll: [],
        productsObj: {},
        userList: getParameterByName('userlist') ? getParameterByName('userlist').split(',') : [],
        zonesObj: null,
        formatsObj: null,
        filterByPriceActive: true,
        filterByFormatActive: false,
        filterByAvailabilityActive: false,
        filterByLocationsActive: false,
        filterByZonesActive: false,
        filterByAudienceActive: false,
        filterByIdActive: false,
        modalId: getParameterByName('product') ? getParameterByName('product') : null,
        filtersNumber: 0,
        lastError: null
    }
};

//console.log("app.ts", getInitialState());

const app = (state: AppState = getInitialState(), action: any) => {
    switch (action.type) {
        case constants.SIDEBAR_SET: {
            //console.log("reducer sidebarset", action);
            return { ...state, sidebarOpened: action.payload };
        }
        case constants.SPINNER_SET: {
            //console.log("reducer spinnerspet", action);
            return { ...state, loading: action.payload };
        }
        case constants.OPEN_MODAL: {
            //console.log("openedmodal", action);
            return { ...state, modalId: action.payload };
        }
        case constants.CLOSE_MODAL: {
            //console.log("close modal", action);
            return { ...state, modalId: null };
        }
        case constants.FETCH_INIT: {
            return { ...state, loading: true };
        }
        case constants.FETCH_FAILED: {
            return { ...state, lastError: action.payload };
        }
        case constants.FETCH_DONE: {
            return { ...state, loading: false };
        }
        case constants.USERLIST_SET: {
            //console.log("userList set", action);
            return { ...state, userList: action.payload };
        }
        case constants.FETCHED_PRODUCTS: {
            //console.log("fetched products", action.payload);
            let removedInactive = [];
            let zonesObj = {};
            let formatsObj = {};
            let productsObj = {};
            removedInactive = action.payload.filter((product) => {
                productsObj[product.id] = product;
                if (product.zone && product.zone.name) {
                    zonesObj[product.zone.name] = false;
                }
                if (product.format && product.format.name) {
                    formatsObj[product.format.name] = false;
                }
                if (product.is_active) {
                    return true;
                } else {
                    return false;
                }
            })

            // this is discouraged but works
            // state['zonesObj'] = zonesObj;
            // state['formatsObj'] = formatsObj;
            // state['productsObj'] = productsObj;

            let sortedProducts = removedInactive.sort((a, b) =>
                ComparatorDual(a.ad_price, b.ad_price, a.id, b.id, 1)
            );

            return { ...state, products: sortedProducts, productsAll: removedInactive, productsForFiltering: sortedProducts, "zonesObj": zonesObj, "formatsObj": formatsObj, "productsObj": productsObj };
        }
        case constants.REMOVE_FROM_USERLIST: {
            let newUserList = state.userList;
            //console.log("removeFromUserList", action.payload, newUserList);
            return { ...state, userList: removeInArray(newUserList, action.payload) };
        }
        case constants.SET_FILTERING_CRITERIA: {
            //console.log("SET_FILTERING_CRITERIA reducer", action.payload, state.productsAll);
            let flaggedProducts;
            // let filteredProducts = [];

            const determineFiltersNo = function(action) {
                // //console.log("determineFiltersNo", action.payload.value);
                if ((action.payload.value === true) || action.payload.value.length) {
                    if (action.payload.filterCriteria === "price" && state.filterByPriceActive) {
                        return;
                    } else {
                        state.filtersNumber += 1;
                    }
                } else if ((action.payload.value === false) || (action.payload.value.length === 0) || (action.payload.value[0] && (action.payload.value[0] === 0 && action.payload.value[1] === 1000000))) {
                    state.filtersNumber -= 1;
                }

            }

            // if (action.payload.filterCriteria === "resetFiltersNumber") {
            //   state.filtersNumber = 0;
            //   filteredProducts = [...state.products];
            // }

            if (action.payload.name === "clearAll") {
                flaggedProducts = [...state.products].map((product) => {
                    //console.log("clearAll: ", product);
                    // Filter group specific flags:
                    product.filteredByZone = false;
                    product.filteredByAvailability = false;
                    product.filteredByAudience = false;
                    product.filteredById = false;
                    product.filteredByLocation = false;
                    // Filter specific flags:
                    product.filteredByLocationLandside = false;
                    product.filteredByLocationAirside = false;
                    product.filteredByAudienceLocal = false;
                    product.filteredByAudienceArrival = false;
                    product.filteredByAudienceDeparture = false;
                    product.filteredByAudienceNonschengen = false;
                    product.filteredByAudienceSchengen = false;
                    product.filteredByAudienceIntercontinental = false;

                    return product;
                })
                state.filtersNumber = 0;
                state.filterByPriceActive = false;
                state.filterByFormatActive = false;
                state.filterByAvailabilityActive = false;
                state.filterByLocationsActive = false;
                state.filterByZonesActive = false;
                state.filterByAudienceActive = false;
                state.filterByIdActive = false;
                flaggedProducts = [...state.productsAll];
            } else if (action.payload.filterCriteria === "price") {
                //console.log("filter by price", action.payload.value);
                determineFiltersNo(action);
                state.filterByPriceActive = true;
                flaggedProducts = [...state.productsForFiltering].map((product) => {
                    // //console.log("filter by price: ", product.production_price, action.payload.value);
                    if ( ( (Number(product.ad_price) >= Number(action.payload.value[0])) ) && ( Number(product.ad_price) <= Number(action.payload.value[1] )) ) {
                        //console.log("filteredByPrice true", action.payload.value);
                        product.filteredByPrice = true;
                    } else {
                        //console.log("filteredByPrice false", action.payload.value);
                        product.filteredByPrice = false;
                    }
                    return product;
                })
            } else if (action.payload.filterCriteria === "format") {
                // //console.log("filter by format", action.payload);
                determineFiltersNo(action);
                if (action.payload.filtersNo > 0) {
                  state.filterByFormatActive = true;
                  //console.log("filterByFormatActive true", state.filterByFormatActive);
                } else {
                  state.filterByFormatActive = false;
                  //console.log("filterByFormatActive false", state.filterByFormatActive);
                }
                flaggedProducts = [...state.productsForFiltering].map((product) => {
                    // //console.log("filter by format: ", product);
                    if (product.format && (product.format.name === action.payload.name)) {
                        product.filteredByFormat = action.payload.value;
                    }
                    return product;
                })
            } else if (action.payload.filterCriteria === "zone") {
                // //console.log("filter by zones", action.payload);
                determineFiltersNo(action);
                if (action.payload.filtersNo > 0) {
                  state.filterByZonesActive = true;
                  //console.log("filterByZonesActive true", state.filterByZonesActive);
                } else {
                  state.filterByZonesActive = false;
                  //console.log("filterByZonesActive false", state.filterByZonesActive);
                }
                flaggedProducts = [...state.productsForFiltering].map((product) => {
                    // //console.log("filter by zone: ", product);
                    if (product.zone && (product.zone.name === action.payload.name)) {
                        product.filteredByZone = action.payload.value;
                    }
                    return product;
                })
            } else if (action.payload.name === "availableSoon") {
                //console.log("filter by availableSoon", action.payload);
                determineFiltersNo(action);
                if (action.payload.filtersNo > 0) {
                  state.filterByAvailabilityActive = true;
                  //console.log("filterByAvailabilityActive true", state.filterByAvailabilityActive);
                } else {
                  state.filterByAvailabilityActive = false;
                  //console.log("filterByAvailabilityActive false", state.filterByAvailabilityActive);
                }
                flaggedProducts = [...state.productsForFiltering].map((product) => {
                    //console.log("availableSoon: ", product);
                    if (product.availability === 2) {
                        product.filteredByAvailability = action.payload.value;
                    }

                    return product;
                })
            } else if (action.payload.name === "available") {
                //console.log("filter by available", action.payload);
                determineFiltersNo(action);
                if (action.payload.filtersNo > 0) {
                  state.filterByAvailabilityActive = true;
                  //console.log("filterByAvailabilityActive true", state.filterByAvailabilityActive);
                } else {
                  state.filterByAvailabilityActive = false;
                  //console.log("filterByAvailabilityActive false", state.filterByAvailabilityActive);
                }
                flaggedProducts = [...state.productsForFiltering].map((product) => {
                    //console.log("available: ", product);
                    if (product.availability === 1) {
                        product.filteredByAvailability = action.payload.value;
                    }

                    return product;
                })
            } else if ((action.payload.name === "landside") || ((action.payload.name === "airside"))) {
                console.log("filter by landside or airside", action.payload.filtersNo);
                determineFiltersNo(action);
                if (action.payload.filtersNo > 0) {
                  state.filterByLocationsActive = true;
                  //console.log("filterByLocationsActive true", state.filterByLocationsActive);
                } else {
                  state.filterByLocationsActive = false;
                  //console.log("filterByLocationsActive false", state.filterByLocationsActive);
                }

                flaggedProducts = [...state.productsForFiltering].map((product, idx) => {
                    console.log("flaggedProducts iterator: ", product);

                    Object.keys(action.payload.value).map((locationName, idx) => {

                        if (locationName === "locationAirside") {
                            // console.log("locationAirside", product.is_airside, action.payload.value)
                            product.is_airside && (product.filteredByLocationAirside = action.payload.value[locationName]);
                        } else if (locationName === 'locationLandside') {
                            // console.log("locationLandside", product.is_landside, action.payload.value)
                            product.is_landside && (product.filteredByLocationLandside = action.payload.value[locationName]);
                        }
                    })


                    const locationBoolArray = [product.filteredByLocationAirside, product.filteredByLocationLandside];
                    // console.log("audienceBoolArray", audienceBoolArray);
                    product.filteredByLocation = locationBoolArray.includes(true);
                    // console.log("filteredByAudience", product.filteredByAudience);


                    return product;
                })
            } else if (action.payload.filterCriteria === "audience") {
                // console.log("filter by audience", action.payload.value);
                determineFiltersNo(action);

                if (action.payload.filtersNo > 0) {
                  state.filterByAudienceActive = true;
                  //console.log("filterByAudienceActive true", state.filterByAudienceActive);
                } else {
                  state.filterByAudienceActive = false;
                  //console.log("filterByAudienceActive false", state.filterByAudienceActive);
                }

                flaggedProducts = [...state.productsForFiltering].map((product) => {
                    // console.log("audience: ", product);
                    Object.keys(action.payload.value).map((audienceName, idx) => {
                        // console.log("audienceName: ", audienceName)
                        // if (action.payload.value[audienceName]) {
                            // console.log("audience was checked: ", action.payload.value[audienceName]);
                            // if audience was checked
                            if (audienceName === "arrival") {
                                console.log("arrival", product.is_audience_arrival_area, action.payload.value)
                                product.is_audience_arrival_area && (product.filteredByAudienceArrival = action.payload.value[audienceName]);
                            } else if (audienceName === 'departure') {
                                console.log("departure", product.is_audience_departure_area, action.payload.value)
                                product.is_audience_departure_area && (product.filteredByAudienceDeparture = action.payload.value[audienceName]);
                            }  else if (audienceName === 'intercontinental') {
                                console.log("departure", product.is_audience_intercontinental_flight, action.payload.value)
                                product.is_audience_intercontinental_flight && (product.filteredByAudienceIntercontinental = action.payload.value[audienceName]);
                            }  else if (audienceName === 'local') {
                                console.log("departure local", product.is_audience_local_flight, action.payload.value)
                                product.is_audience_local_flight && (product.filteredByAudienceLocal = action.payload.value[audienceName]);
                            }  else if (audienceName === 'non schengen') {
                                console.log("departure", product.is_audience_non_schengen_flight, action.payload.value)
                                product.is_audience_non_schengen_flight && (product.filteredByAudienceNonschengen = action.payload.value[audienceName]);
                            }  else if (audienceName === 'schengen') {
                                console.log("departure", product.is_audience_schengen_flight, action.payload.value)
                                product.is_audience_schengen_flight && (product.filteredByAudienceSchengen = action.payload.value[audienceName]);
                            }
                        // }

                        const audienceBoolArray = [product.filteredByAudienceArrival, product.filteredByAudienceDeparture, product.filteredByAudienceIntercontinental, product.filteredByAudienceLocal, product.filteredByAudienceNonschengen, product.filteredByAudienceSchengen];
                        // console.log("audienceBoolArray", audienceBoolArray);
                        product.filteredByAudience = audienceBoolArray.includes(true);
                        // console.log("filteredByAudience", product.filteredByAudience);
                    })

                    return product;
                })
            } else if (action.payload.name === "productId") {
                //console.log("filter by id string", action.payload);

                determineFiltersNo(action);

                if (action.payload.filtersNo > 0) {
                  state.filterByIdActive = true;
                  //console.log("filterByIdActive true", state.filterByIdActive);
                } else {
                  state.filterByIdActive = false;
                  //console.log("filterByIdActive false", state.filterByIdActive);
                }


                flaggedProducts = [...state.productsForFiltering].map((product) => {
                    let extId = product.id_external.toString();
                    let oldId = (product.id_old ? product.id_old.toString() : '');
                    //console.log("filter by id", action.payload.value.toString(), extId, RegExp( action.payload.value.toString(), 'gi' ).test( extId ));
                    if (( RegExp( action.payload.value.toString(), 'gi' ).test( extId ) === true) ||  (oldId.length && RegExp( action.payload.value.toString(), 'gi' ).test( oldId ) === true) ) {
                        //console.log("will set true on product");
                        // //console.log("filter by id: ", product.id);
                        product.filteredById = true;
                    } else {
                        product.filteredById = false
                    }
                    return product;
                })
            }
            let sortedProducts = [];
            let onlyVisibleProducts = [];
            // //console.log("flaggedProducts: ", flaggedProducts, 'filteredProducts: ', filteredProducts);
            flaggedProducts.forEach((product) => {
                let filterBools = [
                      ( (state.filterByIdActive === true) ? !(product.filteredById === undefined || product.filteredById === false) : true ),
                      ( (state.filterByAudienceActive === true) ? !(product.filteredByAudience === undefined || product.filteredByAudience === false) : true ),
                      ( (state.filterByAvailabilityActive === true) ? !(product.filteredByAvailability === undefined || product.filteredByAvailability === false) : true ),
                      ( (state.filterByLocationsActive === true) ? !(product.filteredByLocation === undefined || product.filteredByLocation === false) : true ),
                      ( (state.filterByZonesActive === true) ? !(product.filteredByZone === undefined || product.filteredByZone === false) : true ),
                      ( (state.filterByFormatActive === true) ? !(product.filteredByFormat === undefined || product.filteredByFormat === false) : true ),
                      ( (state.filterByPriceActive === true) ? !(product.filteredByPrice === undefined || product.filteredByPrice === false) : true ),
                      // ( product.is_active ? true : false ),
                    ];

                // //console.log("determineVisiblility: ", product, filterBools);
                // if (filterBools.some(cl => cl === false)) {
                //     // //console.log("will return null");
                //     // return null;
                // } else {
                //     // //console.log("will return product");
                //     filteredProducts.push(product);
                // }

                if ( !(filterBools.some(cl => cl === false)) ) {
                    product.isVisible = true;
                    onlyVisibleProducts.push(product);
                } else {
                    product.isVisible = false;
                }
            })

            sortedProducts = onlyVisibleProducts.sort((a, b) =>
                ComparatorDual(a.ad_price, b.ad_price, a.id, b.id, 1)
            );
            // console.log("sortedProducts", sortedProducts);
            // console.log("filteredProducts: ", filteredProducts, 'flaggedProducts', flaggedProducts);

            return { ...state, products: sortedProducts, productsForFiltering: flaggedProducts };
        }

    }
    return state;
};

export default app;
