import React, { useState } from 'react';
import Sidebar from "react-sidebar";
import {connect} from 'react-redux';
import {HashRouter as Router, Route} from "react-router-dom";
import {RootState} from './reducers/index';
import * as actions from './actions/';
import {Button, EButtonType} from './components/Button/index';
import {Input} from './components/Input/index';
import {FormattedMessage, injectIntl} from 'react-intl';
import SearchPage from './containers/SearchPage';
import './App.scss';
import {getParameterByName} from './utils/toSearchParams';
import {EValidationTestType} from './utils/validationConfigs';
import ProductRow from './components/ProductRow';
// import {ReactComponent as IconHamburger} from './assets/icons/hamburger.svg';
import {isXInArray, removeInArray} from './utils/array';
import {ReactComponent as IconClose} from './assets/icons/close.svg';
// import Spinner from './components/Spinner';
import { EInputTextType } from './components/Input/index';
import ReCAPTCHA from "react-google-recaptcha";
import config from './constants/config';


export interface AppProps {
    sidebarOpened: boolean;
    setSidebar: any;
    setUserList: any;
    sendEmail: any;
    setSpinner: any;
    // removeFromUserList: any;
    openModal: any;
    productsObj: any;
    userList: any;
    modalId: any;
    history: any;
    intl: any;
    // logOut: any;
}

const setBodyClassNames = (opened) => {
    if (opened) {
        document.body.classList.add('opened');
        document.body.classList.remove('closed');
    } else {
        document.body.classList.remove('opened');
        document.body.classList.add('closed');
    }
}

const App: React.FC<AppProps> = ({sidebarOpened, setSidebar, setSpinner, sendEmail, setUserList, userList, productsObj, openModal, modalId, history, intl}) => {
    //console.log("functional compoennt");
    // Declare a new state variable, which we'll call "count"
    const [myName, setmyName] = useState('');
    const [myMail, setmyMail] = useState('');
    const [recipientMail, setrecipientMail] = useState('');
    const [recipientName, setrecipientName] = useState('');
    const [message, setmessage] = useState('');
    const [captcha, setcaptcha] = useState('');
    const [successmessage, setsuccessmessage] = useState('');
    const [errormessage, seterrormessage] = useState('');

    const trySendEmail = (e) => {
        e.preventDefault();
        setSpinner(true);
        let emailData = {
            "sender_name": myName,
            "sender_email": myMail,
            "message": message,
            "receiver_names": [recipientName],
            "receiver_emails": [recipientMail],
            "selected_products": userList,
            "recaptcha": captcha
        };

        setsuccessmessage('');
        seterrormessage('');

        sendEmail(emailData, (window as any).urlObj['lang']).then((res) => {
            console.log("email sent, res", res);
            setsuccessmessage(intl.formatMessage({ id: "app.emailsuccess", defaultMessage: "Ihre Mail wurde erfolgreich versendet"}));
            setSpinner(false);
            setmessage('');
            setrecipientMail('');
            setrecipientName('');

        }).catch((err) => {
            console.log("email sent failed, res", err.toString());
            setSpinner(false);
            seterrormessage( err.toString() );
        });

        console.log("onsubmit", e, emailData);
    }

    const onCaptchaChange = (val) => {
        console.log("onCaptchaChange", val);
        setcaptcha(val);
    }

    const doOpenModal = (id) => {
        let languageCode = intl.locale;
        if (userList.length) {
            console.log("HISTORY PUSH, doOpenModal");
            history.push({
                pathname: `/`,
                search: "?" + new URLSearchParams({'lang': languageCode, 'product': id, 'userlist': userList.join(',')}).toString()
            })
        } else {
            console.log("HISTORY PUSH, doOpenModal");
            history.push({
                pathname: `/`,
                search: "?" + new URLSearchParams({'lang': languageCode, 'product': id}).toString()
            })
        }
        openModal(id);
    }

    const doAddToList = (id) => {
        //console.log("addToList", id);
        const newUserList = userList;
        const languageCode = intl.locale;
        const productFromUrl = getParameterByName('product');

        if (!isXInArray(id.toString(), userList)) {
            newUserList.push(id.toString());
            // alert("Product has been added to the list");
            setUserList(newUserList).then((res) => {
                //console.log("then after set list", res);
                // this.props.history.push({
                //     pathname: `/`,
                //     search: "?" + new URLSearchParams({'userlist': newUserList.join(',')}).toString()
                // })

                if (productFromUrl) {
                    console.log("HISTORY REPLACE, doAddToList");
                    history.replace({
                        pathname: `/`,
                        search: "?" + new URLSearchParams({'lang': languageCode, 'product': id, 'userlist': userList.join(',')}).toString()
                    })
                } else {
                    console.log("HISTORY REPLACE, doAddToList");
                    history.replace({
                        pathname: `/`,
                        search: "?" + new URLSearchParams({'lang': languageCode, 'userlist': userList.join(',')}).toString()
                    })
                }
            })
        }
    }

    const removeProduct = (id) => {
        console.log("remove product", id.toString(), userList);
        if (isXInArray(id.toString(), userList)) {
            const newUserList = removeInArray(userList, id.toString());
            const languageCode = intl.locale;
            const productFromUrl = getParameterByName('product');

            console.log("newUserList", newUserList, 'modalId', modalId);
            setUserList(newUserList).then((res) => {
                //console.log("then after set list", res);
                // this.props.history.push({
                //     pathname: `/`,
                //     search: "?" + new URLSearchParams({'userlist': newUserList.join(',')}).toString()
                // })

                if (productFromUrl) {
                    console.log("HISTORY REPLACE, removeProduct");
                    history.replace({
                        pathname: `/`,
                        search: "?" + new URLSearchParams({'lang': languageCode, 'product': modalId, 'userList': newUserList.join(',')}).toString()
                    })
                } else {
                    console.log("HISTORY REPLACE, removeProduct");
                    history.replace({
                        pathname: `/`,
                        search: "?" + new URLSearchParams({'lang': languageCode, 'userList': newUserList.join(',')}).toString()
                    })
                }
            })
            // alert("Product has been removed from the list");
        }
        // removeFromUserList(id);
    }

    return (
        <Router>
            <Sidebar
                pullRight
                rootClassName="sidebarContainer"
                sidebarClassName="sidebar styledScrollbar"
                contentClassName="sidebarContent"
                overlayClassName="sidebarOverlay"
                open={sidebarOpened}
                docked={false}
                styles={{
                    sidebar: {
                        position: 'fixed'
                    }
                }}
                onSetOpen={(opened) => {
                    setBodyClassNames(opened);
                    setSidebar(opened)
                }}
                sidebar={
                    <div className="pt-5 mt-5">
                        <div className="iconWrapper pointer" style={{position: "absolute", right: "47px", top: "32px"}} onClick={() => {setSidebar(false)}}>
                            <IconClose />
                        </div>
                        <h2>
                            {userList.length ? <FormattedMessage id="app.userlist" defaultMessage="Merkliste" /> : <FormattedMessage id="app.emptylist" defaultMessage="Ihre Merkliste ist leer" />}
                        </h2>
                        {userList.map((productId, idx) => {
                            //console.log("userList productId", productId);
                            return productsObj[productId] && (
                                <div key={'myListRow' + idx} className="" style={{marginBottom: '55px'}}>
                                    <ProductRow
                                        className={'p-0'}
                                        key={"productRowInList" + idx}
                                        idx={idx}
                                        billboardImage={productsObj[productId].billboard_image}
                                        airportInfo={productsObj[productId].additional_info_airport}
                                        planImage={ productsObj[productId].plan_image}
                                        locationMapImage={ productsObj[productId].location_map_image}
                                        format={productsObj[productId].format && productsObj[productId].format.name}
                                        formatDetail={productsObj[productId].format && productsObj[productId].format.format_detail}
                                        available={Boolean(productsObj[productId].availability === 1)}
                                        availableSince={productsObj[productId].becomes_available_at}
                                        zone={productsObj[productId].zone && productsObj[productId].zone.name}
                                        zoneDetail={productsObj[productId].zone && productsObj[productId].zone.zone_detail}
                                        idExt={productsObj[productId].id_external}
                                        id={productsObj[productId].id}
                                        arrival={productsObj[productId].is_audience_arrival_area}
                                        departure={productsObj[productId].is_audience_departure_area}
                                        schengen={productsObj[productId].is_audience_schengen_flight}
                                        nonschengen={productsObj[productId].is_audience_non_schengen_flight}
                                        internal={productsObj[productId].is_audience_local_flight}
                                        intercontinental={productsObj[productId].is_audience_intercontinental_flight}
                                        locationAirside={productsObj[productId].is_airside}
                                        locationLandside={productsObj[productId].is_landside}
                                        adPrice={productsObj[productId].ad_price}
                                        isList
                                        openModal={() => doOpenModal(productId)}
                                        removeFromList={() => removeProduct(productsObj[productId].id)}
                                    />
                                </div>
                            )
                        })}
                        {/*<h2>
                            {userList.length ? "Send List" : null}
                        </h2>*/}
                        {userList.length ? <form onSubmit={(e) => {trySendEmail(e)}} style={{maxWidth: '370px'}}>
                            <Input
                                name="myName"
                                required
                                inputWrapperClass="w-100"
                                inputTextType={EInputTextType.Material}
                                value={myName}
                                onChange={(name, value) => setmyName(value)}
                                validationMessageConfig={null}
                                type='text'
                                validationTests={[EValidationTestType.hasValue]}
                                inputWrapperStyle={{}}>
                                {intl.formatMessage({ id: "app.yourname", defaultMessage: "Ihr Name"})}
                            </Input>
                            <Input
                                name="myMail"
                                required
                                inputWrapperClass="w-100"
                                inputTextType={EInputTextType.Material}
                                value={myMail}
                                onChange={(name, value) => setmyMail(value)}
                                validationMessageConfig={null}
                                type='email'
                                validationTests={[EValidationTestType.hasValue]}
                                inputWrapperStyle={{}}>
                                {intl.formatMessage({ id: "app.email", defaultMessage: "Email"})}
                            </Input>
                            <Input
                                name="recipientName"
                                required
                                inputWrapperClass="w-100"
                                inputTextType={EInputTextType.Material}
                                value={recipientName}
                                onChange={(name, value) => setrecipientName(value)}
                                validationMessageConfig={null}
                                type='text'
                                validationTests={[EValidationTestType.hasValue]}
                                inputWrapperStyle={{}}>
                                {intl.formatMessage({ id: "app.receivername", defaultMessage: "Empfänger Name"})}
                            </Input>
                            <Input
                                type='email'
                                required
                                name="recipientMail"
                                inputWrapperClass="w-100"
                                inputTextType={EInputTextType.Material}
                                value={recipientMail}
                                onChange={(name, value) => setrecipientMail(value)}
                                validationMessageConfig={null}
                                validationTests={[EValidationTestType.hasValue]}
                                inputWrapperStyle={{}}>
                                {intl.formatMessage({ id: "app.receiveremail", defaultMessage: "Empfänger E-Mail"})}
                            </Input>
                            <Input
                                name="message"
                                required
                                inputTextType={EInputTextType.Material}
                                inputWrapperClass="w-100 mb-5"
                                value={message}
                                onChange={(name, value) => setmessage(value)}
                                validationMessageConfig={null}
                                type='text'
                                validationTests={[EValidationTestType.hasValue]}
                                inputWrapperStyle={{}}>
                                {intl.formatMessage({ id: "app.remarks", defaultMessage: "Bemerkungen"})}
                                </Input>
                                <ReCAPTCHA
                                    className="captcha"
                                    sitekey={config.recaptchaKey}
                                    onChange={onCaptchaChange}
                                />
                                <div className={successmessage ? "my-3 colorGreen2" : "my-3 colorRed"} style={{height: '36px', fontSize: '18px'}}>{successmessage.length ? successmessage : errormessage}</div>
                            <Button
                                submit={true}
                                className="mt-0"
                                buttonType={EButtonType.InvertedPillMedium}
                                >
                                <FormattedMessage id="app.submit" defaultMessage="Submit" />
                            </Button>
                        </form> : null}
                    </div>
                }>
                    <div className="toBeRemoved d-none"></div>
            </Sidebar>
            <Route
                exact
                path="/"
                render={(props) =>
                  <SearchPage {...props} removeFromList={removeProduct} openModal={doOpenModal} addToList={doAddToList}/>
                }
            />
    </Router>
    );
}

function mapStateToProps(state : RootState, ownProps : any) {
    return {
        ...ownProps,
        ...state.app,
    }
}

function mapDispatchToProps(dispatch : any) {
    return {
        setSidebar: (val) => dispatch(actions.setSidebar(val)),
        setSpinner: (val) => dispatch(actions.setSpinner(val)),
        setUserList: (val) => dispatch(actions.setUserList(val)),
        sendEmail: (val, langCode) => dispatch(actions.sendEmail(val, langCode)),
        // removeFromUserList: (val) => dispatch(actions.removeFromUserList(val)),
        openModal: (val) => dispatch(actions.openModal(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(App));
