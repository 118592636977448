import React from 'react';
import {connect} from 'react-redux';
import {RootState} from '../../reducers/index';
import {Dispatch} from 'redux';
import {FormattedMessage, injectIntl} from 'react-intl';
// import {FormattedMessage} from 'react-intl';
import * as actions from '../../actions/';
// import { updateIntl } from 'react-intl-redux'
import './Header.scss';
// import { store } from '../../index';
// import {Button, EButtonType} from '../../components/Button/index';
import { DropdownCollapse } from '../../components/DropdownCollapse/index';
// import { ReactComponent as IconLogout } from '../../assets/icons/logout.svg';
import apgLogoWhite from '../../assets/imgs/logo-white.png';
import apgLogoRed from '../../assets/imgs/logo-red.png';

interface State {}

interface Props {
    productPage?: boolean;
    locales: any;
    language: string;
    changeLanguage: any;
    intl: any;
}

class Header extends React.Component<Props, State> {
    // constructor(props : any) {
        //console.log("Header constructor called");

        // super(props);
    // }

    componentDidMount() {
        //console.log('Header app did mount');
    }

    componentWillUnmount() {}

    render() {
        return (
            <header id="printHeader" className={this.props.productPage ? 'headerContainer productPage' : 'headerContainer'}>
                <div className="responsiveContainer">
                    <div className="headerTitle">
                        {this.props.productPage ?
                            <a href="/">
                                <img alt="logo" src={apgLogoRed}/>
                            </a> :
                            <a href={ this.props.intl.formatMessage({ id: "app.homelink", defaultMessage: "https://www.apgsga-airport.ch/en/"}) }>
                                <img alt="logo" src={apgLogoWhite}/>
                            </a>
                        }
                    </div>
                    <div className="headerContent">
                        {/*<DropdownCollapse label="Advertising products" className="absolutePositioned dropdownLink">
                        <div className="">PRODUCT 1</div>
                        <div className="">PRODUCT 2</div>
                        <div className="">PRODUCT 3</div>
                        <div className="">PRODUCT 4</div>
                        </DropdownCollapse>
                        <DropdownCollapse label="Company" className="absolutePositioned dropdownLink"/>
                        <DropdownCollapse label="EN" className="absolutePositioned dropdownLink languageDropdown"/>
                        <a href="https://apgsga.ch/en/" target="_blank" className="headerLink">Contact</a>
                        <DropdownCollapse label="TOOLS" className="absolutePositioned dropdownLink"/>*/}
                        <a href="https://www.apgsga-airport.ch" className="headerLink">
                        <FormattedMessage id="app.backtostart" defaultMessage="Zurück zur Startseite" /></a>
                        <DropdownCollapse label={this.props.language.toUpperCase()} className="absolutePositioned dropdownLink languageDropdown">
                            {this.props.language !== 'fr' && <div className="langCode" onClick={() => {
                                this.props.changeLanguage('fr');
                            }}>FR</div>}
                            {this.props.language !== 'en' && <div className="langCode" onClick={() => {
                                this.props.changeLanguage('en');
                            }}>EN</div>}
                            {this.props.language !== 'it' && <div className="langCode" onClick={() => {
                                this.props.changeLanguage('it');
                            }}>IT</div>}
                            {this.props.language !== 'de' && <div className="langCode" onClick={() => {
                                this.props.changeLanguage('de');
                            }}>DE</div>}
                        </DropdownCollapse>
                    </div>
                    {this.props.children ? <div className="headerChildren w-100">
                        {this.props.children}
                    </div> : null}
                </div>
{/*                <div className="responsiveContainer">
                    <div className="disclaimer">
                        <FormattedMessage id="app.disclaimer" defaultMessage="Preisänderungen und Verfügbarkeiten vorbehalten. Alle Preise verstehen sich exkl. MwSt." />
                    </div>
                </div>*/}
            </header>
        );
    }
}

function mapStateToProps(state : RootState, ownProps : any) {
    return {
        ...ownProps,
        ...state.app
    }
}

function mapDispatchToProps(dispatch : Dispatch < actions.ACTION >) {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Header));
