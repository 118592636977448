export function Comparator(a, b, direction, string) {
  if (string) {
    return a.localeCompare(b) * direction;
  }
  return (a - b) * direction;
}

export function ComparatorDual(a, b, c,d,direction) {
  return ((a - b) * direction) || ((c - d) * direction);
}
