import prodConfig from './config/config.prod.json';
import stageConfig from './config/config.stage.json';
import localConfig from './config/config.local.json';

const TEST_HOST = "apg-airport-product-finder-stage.ch.aldryn.io";
const PROD_HOST = "products.apgsga-airport.ch";

const isProd = window.location.host === PROD_HOST;
const isStage = window.location.host === TEST_HOST;

let config;

const disableLogging = () => {
  if(!window.console) (((window as any).console)) = {};
  var methods = ["log", "debug", "warn", "info"];
  (window as any).console.off = {};
  for(var i=0;i<methods.length;i++){
      (console as any).off[methods[i]] = console[methods[i]];
      console[methods[i]] = function(){};
  }
}

(window as any).enableLogging = () => {
  var methods = ["log", "debug", "warn", "info"];
  for(var i=0;i<methods.length;i++){
      (console as any)[methods[i]] = (window as any).console.off[methods[i]];
  }
}


if (isProd) {
  //console.log("isProduction");
  disableLogging();
  config = prodConfig;
} else if (isStage) {
  //console.log("isStage");
  config = stageConfig;
  disableLogging();
} else {
  // disableLogging();
  //console.log("isNot Production or Stage");
  config = localConfig;
}

export default config;
