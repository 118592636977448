import * as React from 'react';
import style from './Spinner.module.scss'; // Import css modules stylesheet as styles
import logo from '../../assets/imgs/apg.png';
import classNames from 'classnames';


export interface Props {
    color?: string;
    text?: string;
    small?: boolean;
    sticky?: boolean;
}

const Spinner = (props: Props) => (
  <div className={classNames(style.loading, "spinnerLoading", {
      [style.small]: props.small,
      [style.sticky]: props.sticky
  }
  )}>
    <div className={classNames(style.wrapper,{
        [style.sticky]: props.sticky}
    )}>
      <div className={style.loader}>
            <svg
              version="1.1"
              id="loader-1"
              x="0px"
              y="0px"
              viewBox="0 0 50 50"
            >
              <path d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z">
                <animateTransform
                  attributeType="xml"
                  attributeName="transform"
                  type="rotate"
                  from="0 25 25"
                  to="360 25 25"
                  dur="0.8s"
                  repeatCount="indefinite"
                />
              </path>
            </svg>
      </div>
      <div className={style.logo}>
        <img src={logo} alt="APG Logo"/>
      </div>
    </div>
    <p>{props.text}</p>
  </div>
);

export default Spinner;
