import * as React from 'react';
import classNames from 'classnames';
import { exists } from '../../../utils/variableEvaluation';
import { EInputTextType } from '../index';
import styles from './InputText.module.scss'; // Import css modules stylesheet as styles


interface IState {
    value: any;
}

interface IProps {
  isFocused: boolean;
  isLoading: boolean;
  required?: boolean;
  name?: string;
  value?: string[] | string | number;
  defaultValue?: string[] | string | number;
  placeholder: string;
  type: string;
  onChange?: (name: string, value: string) => void;
  additionalClass?: string;
  isDisabled?: boolean;
  inputTextType?: EInputTextType;
  onBlur?: any;
}

export class InputText extends React.Component<IProps, IState> {
    constructor(props : any) {
        super(props);

        this.state = {
            value: this.props.defaultValue || '',
        }
    }
    private inputRef: HTMLInputElement;
    private focusTimeoutId;

    public componentDidMount() {
        this.focusTimeoutId = setTimeout(() => {
          if (this.props.isFocused) {
            this.inputRef.focus();
          }
        }, 0);
    }

    public componentWillUnmount() {
        clearTimeout(this.focusTimeoutId);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.value !== this.props.value) {
            if (this.state.value !== this.props.value) {
                this.setState({
                    value: this.props.value
                })
            }
        }
    }

    private handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;
        this.setState({'value': value});
        this.props.onChange && this.props.onChange(name, value);
    };

    private handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;
        // this.setState({'value': value});
        this.props.onBlur && this.props.onBlur(name, value);
    };


    public render() {
    const {
      name,
      value,
      placeholder,
      type,
      isFocused,
      isLoading,
      additionalClass,
      isDisabled,
      inputTextType,
      required
    } = this.props;

    const inputClass = classNames(styles.inputText, {
      [additionalClass]: exists(additionalClass),
      [styles.hasValue]: exists(value),
      [styles.textStandard]: inputTextType === EInputTextType.Standard,
      [styles.textMaterial]: inputTextType === EInputTextType.Material
    });

    return (
      <input
        name={name}
        type={type}
        placeholder={placeholder}
        value={this.state.value}
        onBlur={this.handleBlur}
        onChange={this.handleChange}
        className={inputClass}
        ref={input => isFocused && (this.inputRef = input)}
        autoFocus={isFocused}
        disabled={isDisabled || isLoading}
        required={required}
      />
    );
    }
}
