import * as React from 'react';
import Slider from 'rc-slider';
// We can just import Slider or Range to reduce bundle size
// import Slider from 'rc-slider/lib/Slider';
// import Range from 'rc-slider/lib/Range';
import 'rc-slider/assets/index.css';

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

interface State {
    value: any;
}

interface Props {
    range?: boolean;
    value?: any;
    min: number;
    max: number;
    priceMax: number;
    priceMin: number;
    defaultValue?: any;
    onChange?: Function;
    onAfterChange?: Function;
}

class InputSlider extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            value: [0,this.props.max]
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        //console.log("componenentDidUpdate slider", prevProps, prevState, this.props, this.state);
        if (prevProps.priceMin !== this.props.priceMin || prevProps.priceMax !== this.props.priceMax) {
            if (this.state.value[0] !== this.props.priceMin || this.state.value[1] !== this.props.priceMax) {
                this.setState({
                    value: [this.props.priceMin, this.props.priceMax]
                })
            }
        }
    }

    onChange = (val) => {
        this.setState({
            value: val
        });
    }

    render() {
        return this.props.range ? (
            <div className="sliderWrapper">
                <Range
                    step={100}
                    onChange={this.onChange}
                    onAfterChange={this.props.onAfterChange}
                    min={this.props.min}
                    max={this.props.max}
                    defaultValue={this.props.defaultValue}
                    tipFormatter={value => value.toLocaleString() }
                    value={this.state.value}
                />
            </div>
        ) : (
            <div className="sliderWrapper">
                <Slider min={20} defaultValue={20} marks={{ 20: 20, 40: 40, 100: 100 }} step={null} />
            </div>
        )
    }
}

export default InputSlider
