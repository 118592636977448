import * as React from 'react';
import { InputWrapper } from './InputWrapper';
import { InputLabel } from './Label/index';
import { EInputType, InputContent } from './Content/index';
import { renderDefinedTrue } from '../../utils/react';
import { ValidationMessage, ValidationMessageProps } from '../ValidationMessage/index';
import { EValidationTestType } from '../../utils/validationConfigs';

export enum EInputTextType {
  Standard = 'Standard',
  Material = 'Material'
}

export interface IInputConfig {
  id?: string;
  name?: string;
  placeholder?: string;
  inputWrapperClass?: string;
  defaultValue?: any;
  defaultChecked?: boolean;
  relativeDropdown?: boolean;
  required?: boolean;
  checked?: boolean;
  label?: string | JSX.Element;
  checkboxLabel?: string;
  dropdownLabel?: string;
  value?: any;
  forcedValue?: any;
  type?: string;
  disabled?: boolean;
  inputWrapperStyle?: React.CSSProperties;
  selectOptions?: IInputConfig[];
  radioOptions?: IInputConfig[];
  inputType?: EInputType;
  inputTextType?: EInputTextType;
  isLoading?: boolean;
  isFocused?: boolean;
  clearable?: boolean;
  onBlur?: any;
  style?: any;
  customComponent?: JSX.Element;
  validationMessageConfig?: ValidationMessageProps;
  defaultValidationMessageConfig?: ValidationMessageProps;
  validationTests?: EValidationTestType[];
  // @TODO fix it for radio buttons!
  onChange?: (name: any, value?: any) => void;
  onDropdownOpen?: (isOpen: boolean) => void;
}
export class Input extends React.Component<IInputConfig, {}> {
  render(): JSX.Element {
    const {
      validationMessageConfig,
      inputWrapperStyle,
      children,
      style,
      inputWrapperClass,
    } = this.props;
    return (
      <InputWrapper inputWrapperStyle={inputWrapperStyle} inputWrapperClass={inputWrapperClass || ""}>
        <InputContent inputConfig={this.props} />
        {renderDefinedTrue(children, () => (
          <InputLabel style={style || {}}>{children}</InputLabel>
        ))}
        {renderDefinedTrue(validationMessageConfig, () => (
          <ValidationMessage {...validationMessageConfig} />
        ))}
      </InputWrapper>
    );
  }
}
