import * as React from 'react';
import { IInputConfig } from '../index';
import { defined } from '../../../utils/variableEvaluation';
import { InputText } from '../Text/index';
import { InputSelect } from '../Select/input';
import { InputCheckbox } from '../Checkbox/index';
import { renderSwitch } from '../../../utils/react';
import { InputRadio } from '../Radio/index';
import { EInputTextType } from '../index';


export enum EInputType {
  Text = 'Text',
  Checkbox = 'Checkbox',
  Radio = 'Radio',
  Select = 'Select',
  Custom = 'Custom'
}

interface IProps {
  inputConfig: IInputConfig;
}

export class InputContent extends React.Component<IProps, {}> {
  private inputType(): EInputType {
    const {
      type,
      selectOptions,
      radioOptions,
      customComponent
    } = this.props.inputConfig;
    if (type === 'checkbox') {
      return EInputType.Checkbox;
    } else if (defined(selectOptions)) {
      return EInputType.Select;
    } else if (defined(radioOptions)) {
      return EInputType.Radio;
    } else if (defined(customComponent)) {
      return EInputType.Custom;
    } else {
      return EInputType.Text;
    }
  }

  public render(): JSX.Element {
    const {
      name,
      value,
      checked,
      placeholder,
      disabled,
      checkboxLabel,
      type,
      onChange,
      onDropdownOpen,
      isFocused,
      isLoading,
      selectOptions,
      radioOptions,
      inputTextType,
      customComponent,
      onBlur,
      relativeDropdown,
      defaultValue,
      required
    } = this.props.inputConfig;

    return renderSwitch(this.inputType(), {
      [EInputType.Custom]: () => customComponent!,
      [EInputType.Checkbox]: () => (
        <InputCheckbox
          isFocused={isFocused}
          isLoading={isLoading}
          name={name}
          value={value}
          checked={checked}
          label={checkboxLabel}
          type={type!}
          isDisabled={disabled}
          onChange={onChange!}
        />
      ),
      [EInputType.Radio]: () => <InputRadio
        radioOptions={radioOptions}
        />,
      [EInputType.Select]: () => (
        <InputSelect
          isFocused={isFocused}
          isLoading={isLoading}
          name={name!}
          value={value}
          placeholder={placeholder}
          type={type!}
          isDisabled={disabled}
          onChange={onChange}
          onDropdownOpen={onDropdownOpen!}
          selectOptions={selectOptions}
          relativeDropdown={relativeDropdown}
          inputTextType={inputTextType || EInputTextType.Material}
        />
      ),
      [EInputType.Text]: () => (
        <InputText
          isFocused={isFocused}
          isLoading={isLoading}
          name={name}
          defaultValue={defaultValue}
          value={value}
          placeholder={placeholder}
          type={type}
          required={required}
          isDisabled={disabled}
          inputTextType={inputTextType || EInputTextType.Standard}
          onChange={onChange}
          onBlur={onBlur}
        />
      )
    });
  }
}
