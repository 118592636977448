import * as React from 'react';
import { IInputConfig } from '../index';
import { defined } from '../../../utils/variableEvaluation';

import styles from './InputRadio.module.scss'; // Import css modules stylesheet as styles

interface IProps {
  index: number;
  input: IInputConfig;
  defaultChecked?: boolean;
}

export function InputRadioOption(props: IProps) {
  const {
    id,
    name,
    label,
    inputWrapperStyle,
    forcedValue,
    onChange
  } = props.input;
  const handleChange = (e: React.ChangeEvent<any>) => {
    const name = e.target.name;
    const inputValues = e.target.value;
    //console.log("handleChange: ", props);
    onChange({ name, inputValues });
  };
  return (
    <li
      onChange={handleChange}
      className={styles.radio}
      style={defined(inputWrapperStyle) ? inputWrapperStyle : { width: 'auto' }}
    >
      <input
        id={id}
        className={styles.radioInput}
        type="radio"
        value={forcedValue}
        name={name}
        defaultChecked={props.defaultChecked}
      />
      <label htmlFor={id} className={styles.radioLabel}>
        {label}
      </label>
      {/*<i className={styles.radioInputMark} />*/}
    </li>
  );
}
