import * as React from 'react';
import classNames from 'classnames';

import styles from './DropdownCollapse.module.scss'; // Import css modules stylesheet as styles

interface State {
    isOpened: boolean;
}

interface Props {
    label: string;
    className?: string;
    fullWidth?: boolean;
}

export class DropdownCollapse extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            isOpened: false
        };
        this.toggleCollapse = this.toggleCollapse.bind(this);
        this.modifyArrowOnCollapse = this.modifyArrowOnCollapse.bind(this);

        document.body.addEventListener('click', (event) => {
            console.log("body clicked", event.target, (event.target as any).classList.contains('dropdown'));
            if (!(event.target as any).classList.contains('arrowddown')) {
                // document.body.classList.remove('backdrop');
                this.setState({isOpened: false});
            }
        });
    }

    toggleCollapse(e): void {
        console.log("toggleCollapse", e, this.state.isOpened);
        e.stopPropagation();

        this.setState({
            isOpened: !this.state.isOpened
        });
    }

    handleArrowClick = (e) => {
        console.log("arrow clicked");
        e.stopPropagation();
        this.setState({isOpened: !this.state.isOpened});
    }


    modifyArrowOnCollapse() {
        return this.state.isOpened
            ? styles.activeArrow
            : '';
    }

    render() {
        return (<div className={classNames("dropdown", styles.dropdownCollapse, {fullWidth: this.props.fullWidth}, this.props.className)}>
            <div className={styles.labelContainer}>
                <button className={classNames('d-flex', styles.collapseButton)} onClick={this.toggleCollapse}>
                    <span className={styles.label}>
                        {this.props.label}
                    </span>
                    <div className={`${styles.arrow} ${this.modifyArrowOnCollapse()} arrowddown`} onClick={this.handleArrowClick}/>
                </button>
            </div>

            <div className={styles.contentContainer + ' contentContainer'}>
                <div className={classNames(styles.content, {
                        [`${styles.isOpened}`]: this.state.isOpened
                    })}>
                    <div className={styles.paddingContainer}>
                        {this.props.children}
                    </div>
                </div>
            </div>
        </div>);
    }
}
