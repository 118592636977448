import React from 'react';
// import {connect} from 'react-redux';
// import {RootState} from '../../reducers/index';
// import {Dispatch} from 'redux';
// import {Button, EButtonType} from '../../components/Button/index';
// import classNames from 'classnames';
// import * as actions from '../../actions/';
import './ProductList.scss';
import ProductRow from '../../components/ProductRow';
import {isXInArray/*, removeInArray*/} from '../../utils/array';
// import {ReactComponent as IconArrowDown} from '../../assets/icons/arrow-down.svg';

interface State {}

interface Props {
    products: any;
    showingNowNo: number;
    openModal: Function;
    addToList: Function;
    removeFromList: Function;
    productId: string;
    filterByAudiencesNo: number;
    locationAirside: boolean;
    locationLandside: boolean;
    available: boolean;
    availableSoon: boolean;
    filterByZonesNo: number;
    filterByFormatsNo: number;
    priceMax: number;
    priceMin: number;
    userList: any;
}

class ProductList extends React.Component<Props, State> {
    constructor(props : any) {
        super(props);
        this.state = {};

        //console.log("ProductList constructor called");
    }

    // static getDerivedStateFromProps(props, state) {
    //     // Update state so the next render will show the fallback UI.
    //     return null;
    // }

    componentDidMount() {
    }

    componentWillUnmount() {}

    componentDidUpdate(prevProps, prevState, snapshot) {
        //console.log("productlist componenentDidUpdate", prevProps, prevState, snapshot);
    }

    isPresentOnTheList(id) {
        if (isXInArray(id.toString(), this.props.userList)) {
            //console.log("willupdatestate");
            return true;
        } else {
            return false;
        }
    }

    // determineRowClasses(product, existingClasses, idx) {
    //   let classes = [
    //     this.props.productId && (product.filteredById === undefined || product.filteredById === false) && 'd-none',
    //     (this.props.filterByAudiencesNo > 0) && (product.filteredByAudience === undefined || product.filteredByAudience === false) && 'd-none',
    //     (this.props.locationAirside || this.props.locationLandside) && (product.filteredByLocation === undefined || product.filteredByLocation === false) && 'd-none',
    //     (this.props.available || this.props.availableSoon) && (product.filteredByAvailability === undefined || product.filteredByAvailability === false) && 'd-none',
    //     (this.props.filterByZonesNo > 0) && (product.filteredByZone === undefined || product.filteredByZone === false) && 'd-none',
    //     (this.props.filterByFormatsNo > 0) && (product.filteredByFormat === undefined || product.filteredByFormat === false) && 'd-none',
    //     (this.props.priceMin > 0 || this.props.priceMax < 50000) && (product.filteredByPrice === undefined || product.filteredByPrice === false) && 'd-none',
    //   ];
    //   //console.log("determineRowClasses: ", product, classes);
    //   if (classes.some(cl => cl === 'd-none')) {
    //       return existingClasses + ' d-none';
    //   } else {
    //       // this.visibleRowsNow+= 1;
    //       return existingClasses;
    //   }
    //
    //   // if ((this.showingNowFilteredNo < this.showingNowNo)) {
    //   //     this.setState({
    //   //         this.showingNowNo: this.showingNowNo + 50
    //   //     })
    //   // }
    //
    // }

    render() {
        return this.props.products.length ? (
            <div className="productList">
                {this.props.products.map((el, idx) => {
                        return ((idx < this.props.showingNowNo)) ?
                        <ProductRow
                            key={"productRow2" + idx}
                            idx={idx}
                            billboardImage={el.billboard_image}
                            planImage={el.plan_image}
                            locationMapImage={el.location_map_image}
                            format={el.format && el.format.name}
                            formatDetail={el.format && el.format.format_detail}
                            available={Boolean(el.availability === 1)}
                            availableSince={el.becomes_available_at}
                            zone={el.zone && el.zone.name}
                            zoneDetail={el.zone && el.zone.zone_detail}
                            idExt={el.id_external}
                            id={el.id}
                            arrival={el.is_audience_arrival_area}
                            departure={el.is_audience_departure_area}
                            schengen={el.is_audience_schengen_flight}
                            nonschengen={el.is_audience_non_schengen_flight}
                            internal={el.is_audience_local_flight}
                            intercontinental={el.is_audience_intercontinental_flight}
                            locationAirside={el.is_airside}
                            locationLandside={el.is_landside}
                            adPrice={el.ad_price}
                            openModal={this.props.openModal}
                            addToList={this.props.addToList}
                            removeFromList={this.props.removeFromList}
                            isPresentOnTheList={this.isPresentOnTheList(el.id)}
                        /> : null
                    })
                }
            </div>
        ) : null
    }
}

export default ProductList;
