import * as constants from '../constants';
// import { push } from 'connected-react-router';
import config from '../constants/config';
// import * as fetch from 'isomorphic-fetch';

const api = config.apiUrl;

export interface ACTION {
    type: string;
    payload?: any;
}

export const setSidebar = (payload: boolean): ACTION => ({
    type: constants.SIDEBAR_SET,
    payload
});

export const removeFromUserList = (payload: any): ACTION => ({
    type: constants.REMOVE_FROM_USERLIST,
    payload
});

export const openModal = (payload: any): ACTION => ({
    type: constants.OPEN_MODAL,
    payload
});

export const closeModal = (): ACTION => ({
    type: constants.CLOSE_MODAL
});


export const setSpinner = (payload: any): ACTION => ({
    type: constants.SPINNER_SET,
    payload
});

export const setUserList = (payload: any) => async (dispatch) => {
    return dispatch({
        type: constants.USERLIST_SET,
        payload
    })
};

export const setFilters = (payload: any) => async (dispatch) => {
    return dispatch({
        type: constants.SET_FILTERING_CRITERIA,
        payload
    })
};

export const fetchInit = (): ACTION => ({
    type: constants.FETCH_INIT
});

export const fetchDone = (): ACTION => ({
    type: constants.FETCH_DONE
});

export const fetchFailed = (payload: any): ACTION => ({
    type: constants.FETCH_FAILED,
    payload
});

export const updateLocales = (payload: any): ACTION => ({
    type: constants.UPDATE_LOCALES,
    payload
});

export const fetchedProducts = (payload: any): ACTION => ({
    type: constants.FETCHED_PRODUCTS,
    payload
});


export function fetchProducts(langCode) {
    console.log('fetch products', langCode);
    return (dispatch, getState) => {
        // const recruiter = getState().authentication.data.sub;
        // const access_token = getState().authentication.token;
        // //console.log('recruiter', recruiter, 'access_token', access_token);
        return new Promise(function(resolve, reject) {
            dispatch(fetchInit());
            return fetch(
                // 'http://www.mocky.io/v2/5d94acab2f00002d008ff8ce',
                // `${api}/products/?format=json&sharing_token=${config.token}`,
                `${api}/products/?format=json`,
                {
                    method: 'GET',
                    headers: {
                        'Accept-Language': langCode || 'de'
                    // Authorization: `Bearer ${access_token}`
                    }
                }
            )
                .then(response => {
                    if (response.ok) {
                        return response;
                    }
                    throw Error(response.statusText);
                })
                .then(response => response.json())
                .then(results => {
                    resolve("success");
                    dispatch(fetchDone());
                    return dispatch(
                        fetchedProducts(results)
                    );
                })
                .catch(err => {
                    reject(err);
                    return dispatch(fetchFailed(err));
                });
        });
    };
}

export function sendEmail(val, langCode) {
    console.log('sendEmail', val);
    return (dispatch, getState) => {
        return new Promise(function(resolve, reject) {
            dispatch(fetchInit());
            return fetch(
                `${api}/products/selected-list/send-email/`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept-Language': langCode || 'de',
                        'X-CSRFToken': document.cookie.replace(/(?:(?:^|.*;\s*)csrftoken\s*\=\s*([^;]*).*$)|^.*$/, "$1")

                    // Authorization: `Bearer ${access_token}`
                    },
                    body: JSON.stringify(val)
                }
            )
                .then(response => {
                    if (!response.ok) {
                        console.log("response is not ok", response.statusText, response);
                        throw Error(response.statusText);
                    }
                    return response;
                })
                .then(response => response.json())
                .then(results => {
                    resolve("success");
                    return dispatch(fetchDone());
                    // return dispatch(
                    //     fetchedProducts(results)
                    // );
                })
                .catch(err => {
                    console.log("will reject", err);
                    reject(err);
                    return dispatch(fetchFailed(err));
                });
        });
    };
}
