import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './styles/index.scss';
import * as serviceWorker from './serviceWorker';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { createStore, applyMiddleware, Store } from 'redux';
import { Route} from "react-router-dom";
import { ConnectedRouter, routerMiddleware } from 'connected-react-router'
import createRootReducer, { RootState } from './reducers/index';
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl-redux';
import {getParameterByName} from './utils/toSearchParams';
import de from './constants/locales/de.json';
import en from './constants/locales/en.json';
import fr from './constants/locales/fr.json';
import it from './constants/locales/it.json';


import { createHashHistory } from 'history'
//console.log("index", process.env);
const historyS = createHashHistory();

let middleware = applyMiddleware(
  routerMiddleware(historyS), // for dispatching history actions
  thunk,
  logger,
);

const userLang = (navigator.language || (navigator as any).userLanguage).substr(0,2);
const matchedUserLang = ['fr','it','en','de'].includes(userLang);
const messages = {
        'en': en,
        'it': it,
        'de': de,
        'fr': fr
    }

const languageCodeFromUrl = getParameterByName('lang');
const matchedLangFromUrl = ['fr','it','en','de'].includes(languageCodeFromUrl);
const determinedLocale = languageCodeFromUrl ? (matchedLangFromUrl ? languageCodeFromUrl : 'de') : (matchedUserLang ? userLang : 'de');

console.log("languageCodeFromUrl: ", languageCodeFromUrl, 'determinedLocale: ', determinedLocale);
const i18nInitialState = {
    locale: determinedLocale,
    messages: languageCodeFromUrl ? (matchedLangFromUrl ? messages[languageCodeFromUrl] : messages['de']) : (matchedUserLang ? messages[userLang] : messages['de']),
}

export const store = createStore(
    createRootReducer(historyS) as any,
    {intl: i18nInitialState, locales: messages} as any,
    middleware as any
  ) as Store<RootState>;

ReactDOM.render(
  <Provider store={store}>
    <IntlProvider locale={determinedLocale} defaultLocale="en">
        <ConnectedRouter history={historyS}>
            <Route path="/" component={App} />
        </ConnectedRouter>
    </IntlProvider>
  </Provider>,
  document.getElementById('root') as HTMLElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
